// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-card h5 {
  font-weight: 500;
}

.customer-card .customerTitle {
  font-size: 0.875rem;
  line-height: 1rem;
}

.newTable-card {
  cursor: pointer;
}
.newTable-card .avatarImage {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  border: solid 1px var(--grey-lite);
  flex-shrink: 0;
  flex-grow: 0;
}
.newTable-card .companyImage {
  min-width: 40px;
  height: 40px;
  background-size: auto 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
