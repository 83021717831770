// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trayAccordion {
  background-color: var(--grey-f0f0f0);
  padding: 12px 12px 6px 12px;
  border: solid 1px transparent;
  border-radius: 10px;
  transition: var(--trans3);
}
.trayAccordion h2 span {
  font-size: var(--font-size-21);
  color: var(--grey-3c3c3c);
}
.trayAccordion h2 .toggler {
  font-size: var(--font-size-16);
  color: var(--grey-a0a0a0);
}

.trayAccordionOn {
  background-color: var(--white);
  border-color: var(--grey-3c3c3c);
}

.request-member-card .customerAvatar {
  min-width: 120px;
  width: 120px;
  height: 120px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  border: solid 1px var(--border-grey);
}
.request-member-card .main__sub {
  font-size: var(--font-size-16);
  color: var(--grey-787878);
  line-height: 1.25;
}
.request-member-card .chip {
  border-bottom: dotted 1px var(--border-grey);
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.request-member-card .chip .chip__title {
  color: var(--grey-787878);
}
.request-member-card .chip .chip__score {
  color: var(--grey-3c3c3c);
  font-weight: var(--f-600);
}
.request-member-card .ql-editor {
  padding: 0;
  font-size: 1rem;
}
.request-member-card .table__blank td {
  padding: 0.75rem 0;
  font-size: 1rem;
  line-height: 2;
}
.request-member-card .table__blank tr:first-child td {
  border-top: none;
}
.request-member-card .table__blank tr td:first-child {
  font-weight: 500;
  text-transform: uppercase;
}
.request-member-card h1 {
  font-size: 2.5rem;
  font-weight: 400;
  margin: 1rem 0 0 0;
}
.request-member-card h3 {
  font-weight: 400;
}
.request-member-card .companyLogo {
  max-height: 3rem;
  height: 3rem;
  width: auto;
}
.request-member-card .s5-change-card {
  border: solid 1px var(--border-grey);
  border-radius: 0.25rem;
  background-color: var(--white);
}
.request-member-card .s5-change-card s5-percent-change-chip {
  font-size: 0.4rem;
  text-align: right;
  vertical-align: middle;
}
.request-member-card .s5-change-card .s5-change-card__top {
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  font-weight: 200;
  font-size: 0.75rem;
  text-transform: uppercase;
  border-right: solid 1px var(--border-grey);
  background-color: var(--grey-exlt);
  border-radius: 0.25rem 0.25rem 0 0;
}
.request-member-card .s5-change-card h4 {
  text-align: center !important;
  font-size: 1.5rem;
  font-weight: 200;
  padding: 0.4rem;
  margin: 0;
}
.request-member-card .list-group-item {
  border-width: 0 0 1px 0;
  border-color: var(--grey-vrlt);
  margin-bottom: 0;
}
.request-member-card .list-group-item:nth-child(even) {
  background-color: var(--grey-exlt);
}
.request-member-card .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-width: 0;
}
.request-member-card s5-checkbox-list .list-group {
  border: solid 1px var(--border-grey);
  border-radius: 4px;
  max-height: 144px;
  height: 144px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.request-member-card label.label-large {
  font-weight: 400;
  font-size: 0.9375rem;
  text-transform: uppercase;
}
.request-member-card textarea.form-control {
  max-height: 144px;
  height: 144px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
