import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { User } from '../../users/user.model';
import { Customer } from '../member.model';
import { Company } from '../../companies/company.model';


export interface FindMemberOrCompanyResponse {
  Customer: Customer;
  Company: Company;
}

export const FIND_MEMBER_OR_COMPANY_QUERY_NAME = 'FindMemberOrCompanyQuery';


@Injectable({
  providedIn: 'root'
})
export class FindMemberOrCompanyGqlService extends Query<FindMemberOrCompanyResponse> {

  document = gql`
    query ${FIND_MEMBER_OR_COMPANY_QUERY_NAME}($id: String!) {
      Customer: Customer_by_pk(id: $id) {
        id
        name
      }
      Company: Company_by_pk(id: $id) {
        id
        name
      }
    }
  `;


}
