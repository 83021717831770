import { Component, Input, OnInit } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { Tag } from '../tag.model';
import { DeleteTagGqlService } from '../tags-gql/delete-tag-gql.service';
import { ListTagsGqlService } from '../tags-gql/list-tags-gql.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 's5-tags-table',
  templateUrl: './tags-table.component.html',
  styleUrls: ['./tags-table.component.scss']
})
export class TagsTableComponent implements OnInit {

  // @Input() tags: Tag[];
  @Input() clientId: string;

  globalAdmin = false;
  id: string;
  newTag:Tag;

  tags$:Observable<Tag[]>;

  constructor(
    private listTagsGqlService: ListTagsGqlService,
    private delteTagGqlService: DeleteTagGqlService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.globalAdmin = this.route.snapshot.data.globalAdmin;
    this.id = this.route.snapshot.params['id'];
    if (this.globalAdmin && !this.id) {
      this.tags$ = of([]);
    } else {
      this.tags$ = this.listTagsGqlService.subscribe()
      .pipe(map(({ data }) => {
        return data.Tag;
      }));
    }
  }

  addTag() {
    // if (this.newTag) {
    //   this.newTag = undefined;
    // } else {
      this.newTag = { client_id: this.clientId, inlineEdit:false };
    // }
  }

  edit(tag:Tag) {
    tag.inlineEdit = true;
  }

  async delete(tag:Tag) {
    if (confirm("Are you sure you want to delete this tag?")) {
      await this.delteTagGqlService.mutate({ id: tag.id }).toPromise();
    }
  }

}
