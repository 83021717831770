import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagComponent } from './tag-component/tag.component';
import { TagsEditorComponent } from './tags-editor-component/tags-editor.component';
import { TagsTableComponent } from './tags-table-component/tags-table.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { TagsColorPickerComponent } from './tags-color-picker/tags-color-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


@NgModule({
  declarations: [
    TagsEditorComponent,
    TagComponent,
    TagsTableComponent,
    TagsColorPickerComponent
  ],
  exports: [
    TagsEditorComponent,
    TagComponent,
    TagsColorPickerComponent,
    TagsTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ColorPickerModule,
    TooltipModule.forRoot()
  ]
})
export class TagsModule { }
