import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class UpdateDynamicFieldService extends Mutation {

  document = gql`
  mutation (
    $id: String!
    $label: String!
    $type: String!
    $description: String
    $dataName: String!
    $entityName: String!
    $required: Boolean!
    $flag: Boolean
  ) {
    update_DynamicField(
      where: {
        id: {_eq: $id}
      },
      _set: {
        label: $label
        type: $type
        description: $description
        data_name: $dataName
        entity_name: $entityName
        required: $required
        flag: $flag
      }
    ) {
      affected_rows
    }
  }
  `;
}
