import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';

import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class StrategicInitiativeDeleteService extends Mutation {

  document = gql`
    mutation($id: String!) {
      delete_StrategicInitiative_by_pk(id: $id) {
        id
      }
    }
  `;
}
