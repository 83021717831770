import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestFulfillFormComponent } from './request-fulfill-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SingleSelectModule } from 'app/shared/single-select/single-select.module';
import { FilterModule } from 'app/shared/Filter/filter.module';
import { UtilsModule } from 'app/shared/utils/utils.module';
// import { CustomerCardComponent } from 'app/+client-admin/members/member-card/member-card.component';
import { RouterModule } from '@angular/router';
import { TableSortModule } from 'app/shared/table-sort/table-sort.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import { SendPrompt } from 'app/+client-admin/prompts/send-prompt';
// import { PromptSubNavComponent } from 'app/+client-admin/prompts/prompt-subnav';
// import { PhoneInputComponent } from 'app/shared/phone-input/phone-input';
import { PhoneInputModule } from 'app/shared/phone-input/phone-input.module';
// import { QuillEditorComponent, QuillModule } from 'ngx-quill';
import { SpinnySaveButtonModule } from 'app/shared/spinny-save-button/spinny-save-button.module';
import { RequestSendPromptComponent } from './request-send-prompt/request-send-prompt.component';
import { RequestTrustedContactComponent } from './request-trusted-contact/request-trusted-contact.component';
// import { MemberViewPageComponent } from 'app/+client-admin/members/member-view-page/member-view-page.component';
import { RequestMemberCardComponent } from './request-member-card/request-member-card.component';
import { PercentChangeChipModule } from 'app/shared/percent-change-chip/percent-change-chip.module';
import { RequestStoryCardComponent } from './request-story-card/request-story-card.component';
import { TrayModule } from 'app/shared/tray/tray.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { InfiniteSelectModule } from 'app/shared/infinite-select/infinite-select.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableControlsDirective } from 'app/shared/directives/table-controls.directive';
import { StrategicInitiativesModule } from 'app/+client-admin/strategic-initiatives/strategic-initiatives.module';
// import { AlertModule } from 'ngx-bootstrap/alert';


@NgModule({
  declarations: [
    RequestFulfillFormComponent,
    RequestTrustedContactComponent,
    // MemberViewPageComponent,
    RequestMemberCardComponent,
    RequestStoryCardComponent,
    TableControlsDirective
    // SendPrompt,
    // PromptSubNavComponent,
    // CustomerCardComponent,

    // CustomerCardComponent
  ],
  exports: [
    RequestFulfillFormComponent

    // CustomerCardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SingleSelectModule,
    FilterModule,
    UtilsModule,
    RouterModule,
    TableSortModule,
    TooltipModule,
    PhoneInputModule,
    // QuillModule,
    SpinnySaveButtonModule,
    // AlertModule,
    PercentChangeChipModule,
    TrayModule,
    AccordionModule,
    InfiniteSelectModule,
    NgSelectModule,
    StrategicInitiativesModule
  ]
})
export class RequestFulfillFormModule { }
