import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { Customer } from '../member.model';


export const MEMBER_LIST_PICKER_QUERY_NAME = 'MemberListPickerQuery';

export interface MemberListPickerQueryResults {
  members: [Customer];
  total: {
    aggregate: {
      count: number;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ListMembersPickerGqlService extends Query<MemberListPickerQueryResults> {

  document = gql`
    query ${MEMBER_LIST_PICKER_QUERY_NAME} (
      $limit: Int!, 
      $offset: Int!,
      $search: String!
      ) {
      members: Customer(
        limit: $limit, 
        offset: $offset, 
        order_by: { lname: asc },
        where: { _or: [
          { name: { _ilike: $search } },
          { company: { name: { _ilike: $search } } }
        ] }
      ) {
        id
        name
        avatarImage {
          id
          url
          mimeType
        }
        company {
          id
          name
        }
      }
      total: Customer_aggregate(
        where: { _or: [
          { name: { _ilike: $search } },
          { company: { name: { _ilike: $search } } }
        ] }
      ) {
        aggregate {
          count
        }
      }
    }
  `;
}
