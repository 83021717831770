import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client } from 'app/+client-admin/clients/client.model';
import { Customer } from 'app/+client-admin/members/member.model';
import { Prompt } from 'app/+client-admin/prompts/prompt.model';
import { PromptGroup } from 'app/+client-admin/prompts/promptGroup.model';
import { CreateSentPromptGqlService } from 'app/+client-admin/prompts/prompts-gql/create-sent-prompt-gql.service';
import { CustomerGroupGqlService } from 'app/+client-admin/prompts/prompts-gql/customer-group-gql.service';
import { SendPromptGqlService } from 'app/+client-admin/prompts/prompts-gql/send-prompt-gql.service';
import { SendPromptDto } from 'app/+client-admin/prompts/send-prompt.dto';
import { User } from 'app/+client-admin/users/user.model';
import { Utils } from 'app/shared/utils/Utils';
import cuid from 'cuid';
import { Request } from '../../request.model'
import { cloneDeep } from 'lodash';
import { SendPromptPayload } from 'app/+client-admin/prompts/send-prompt-payload.model';
import { PromptService } from 'app/+client-admin/prompts/prompt.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 's5-request-trusted-contact',
  templateUrl: './request-trusted-contact.component.html',
  styleUrls: ['./request-trusted-contact.component.scss']
})

// Apparently, not used anywhere?
export class RequestTrustedContactComponent implements OnInit {

  @Input() fulfillmentMembers: any[] = [];
  @Input() request: Request;
  @Input() client: Client;
  @Input() user: User;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  trustedContactGreeting = '';
  trustedContactSubject = '';
  useTrustedContactGreeting = false;

  constructor() { }

  ngOnInit(): void { }

  // checkForm() {
    
  // }

  // cancel(form: NgForm) {
  //   this.fulfillmentMembers.map((member: any) => {
  //     if (member.trustedContact) {
  //       delete member.trustedContact.sendEmail;
  //       delete member.trustedContact.sendSMS;
  //     }
  //   });
  //   this.action.emit({cancelled: true});
  // }

  // done() {
  //   this.action.emit({
  //     done: true, 
  //     useCustomGreeting: this.useTrustedContactGreeting, 
  //     customGreeting: this.trustedContactGreeting,
  //     customSubject: this.trustedContactSubject
  //   });
  // }

  // public setAllEmail(bool) {
  //   this.fulfillmentMembers.forEach((ci: any) => {
  //     if (document.getElementById('cust-' + ci.id)) {
  //       ci.trustedContact.sendEmail = bool;
  //     }
  //   });
  //   this.checkForm();
  // }

  // public setAllSMS(bool) {
  //   this.fulfillmentMembers.forEach((ci: any) => {
  //     if (document.getElementById('cust-' + ci.id)) {
  //       ci.trustedContactsendSMS = bool;
  //     }
  //   });
  //   this.checkForm();
  // }

  // public setTrustedContactGreeting() {
  //   this.trustedContactGreeting =
  //     this.user.name + ' from ' + this.client.name + ' has sent you a prompt: ';
  //   this.trustedContactSubject = `Share your voice with ${this.user.name} at ${this.client.name}`;
  // }

  // public selectTrustedContact(member) {
  //   this.action.emit({member});
  // }
}
