import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CapitalizePipe } from './capitalize.pipe';
import { EllipsisPipe } from './EllipsisPipe';
import { IterablePipe } from './IterablePipe';
import { KeysPipe } from './KeyPipe';
import { MorePipe } from './MorePipe';
import { CallbackPipe } from '../callback-pipe/callback.pipe'
import { PropertyFilter } from './property-filter.pipe';
import { StoryConfigContainerFilterPipe } from './StoryConfigContainerFilter';
import { SafeHtmlPipe } from './SafeHtmlPipe';
// import { Utils }  from './Utils';

@NgModule({
  declarations: [
    EllipsisPipe,
    IterablePipe,
    KeysPipe,
    MorePipe,
    StoryConfigContainerFilterPipe,
    CapitalizePipe,
    PropertyFilter,
    CallbackPipe,
    SafeHtmlPipe,
    // Utils
  ],
  exports: [
    EllipsisPipe,
    IterablePipe,
    KeysPipe,
    MorePipe,
    StoryConfigContainerFilterPipe,
    CapitalizePipe,
    PropertyFilter,
    CallbackPipe,
    SafeHtmlPipe,
    // Utils
  ],
  imports: [CommonModule, FormsModule],
})
export class UtilsModule {

}
