import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import chroma from 'chroma-js'

@Component({
  selector: 's5-tags-color-picker',
  templateUrl: './tags-color-picker.component.html',
  styleUrls: ['./tags-color-picker.component.scss']
})
export class TagsColorPickerComponent implements OnInit {

  @Input() color:string;

  @Output() colorChange: EventEmitter<string> = new EventEmitter<string>();


  constructor() { }

  ngOnInit(): void {
    if (!this.color) {
      this.chooseRandomColor();
    }
  }

  chooseRandomColor() {

    console.log("attempting to pick random color");

    this.color = chroma.random().brighten(2).hex();
    this.colorChange.emit(this.color);
  }

}
