import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class InsertOppertunityExternalContactGqlService extends Mutation {

  document = gql`

    mutation (
        $id: String!
        $opportunity_id: String!
        $externalContact_id: String!
    ) {
      insert_Opportunity_ExternalContact_one(
        object: {
          id: $id
          opportunity_id: $opportunity_id
          externalContact_id: $externalContact_id
        }) {
        id opportunity_id externalContact_id
        externalContact {
          id client_id externalContact_id name email account_id companyName
        }
      }
    }
  `;
}
