// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-danger {
  box-shadow: none;
}

.border-danger:focus {
  box-shadow: rgba(var(--bs-danger-rgb), 50%) 0 0 0 0.25rem;
}

.card-tiled h6 {
  color: var(--grey-mddk);
}
.card-tiled h1 {
  font-weight: var(--f-500);
  font-size: 60px;
}

.name-input {
  min-width: 18vh;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
