import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from "@angular/router";

import { BrowserModule } from '@angular/platform-browser';
import { makeStateKey } from '@angular/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DragulaModule } from 'ng2-dragula';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { NgxChartsModule } from '@swimlane/ngx-charts';

import { init, instrumentAngularRouting, BrowserTracing, createErrorHandler, TraceService } from "@sentry/angular-ivy";


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TextboxModalModule } from './shared/textbox-modal/textbox-modal.module';

import { QuillModule } from 'ngx-quill';


import { environment } from '../environments/environment';
import { UserIdleModule } from 'angular-user-idle';

import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { RequestFormModule } from './+client-admin/requests/request-form/request-form.module';
import { RequestFulfillFormModule } from './+client-admin/requests/request-fulfill-form/request-fulfill-form.module';
import { RequestService } from './+client-admin/requests/request.service';
import { RatingModule } from 'ngx-bootstrap/rating';

import { InMemoryCache, split } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { AuthHttpInterceptor, AuthService } from '@auth0/auth0-angular';
import { AuthModule } from '@auth0/auth0-angular';

import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { NgSelectModule } from '@ng-select/ng-select';
import { CurrentUserService } from './shared/current-user/current-user.service';
import { User } from './+client-admin/users/user.model';
import { NotFoundComponent } from './not-found/not-found.component';

const STATE_KEY = makeStateKey<any>('apollo.state');

const proto = window.location.protocol + '//';
const host = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
const landingPath = proto + host + window.location.pathname;

export function createApollo(httpLink: HttpLink, authService:AuthService) {
  // Create an http link:
  const http = httpLink.create({
    uri: environment.gqlUrl,
  });

  // Create a WebSocket link:
  const ws = new WebSocketLink({
    uri: environment.gqlWsUrl,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        const token = await authService.getAccessTokenSilently().toPromise();
        return {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      }
    },
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({query}) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    ws,
    http,
  );


  const cache = new InMemoryCache({
    typePolicies: {
      Client: {
        fields:  {
          prompts: {
            merge: false
          }
        }
      },
      Customer: {
        fields: {
          trustedContacts: {
            merge: false
          }
        }
      },
      Company: {
        fields: {
          trustedContacts: {
            merge: false
          }
        }
      },
      Request: {
        fields: {
          fulfillmentBoards: {
            merge: false
          },
          fulfillmentCustomer: {
            merge: false
          }
        }
      },
      Prompt: {
        fields: {
          disallowedPermissions: {
            merge: false
          },
          types: {
            merge: false
          }
        }
      },
      RequestFulfillmentBoards: {
        keyFields: ['board_id', 'request_id']
      },
      Tag_Media: {
        keyFields: ['media_id', 'tag_id']
      },
      StrategicInitiative_Asset: {
        keyFields: ['asset_id', 'strategic_initiative_id']
      },
      TrustedContact_Company: {
        keyFields: ['company_id', 'user_id']
      },
      TrustedContact_Member: {
        keyFields: ['member_id', 'user_id']
      },
      PromptGroup: {
        fields: {
          prompts: {
            merge: false
          }
        }
      }
    }
  });

  return {
    link,
    cache
  };
}

if (environment.environmentName === 'stage' || environment.environmentName === 'production' || environment.environmentName === 'development') {
  
  init({ // sentry init
    dsn: environment.sentryDsn,
    environment: environment.environmentName,
    // release:  <--  TODO; the tagging CICD step can be moved to run first (which generates the version number) and then we can inject it here
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new BrowserTracing({
        tracingOrigins: ["localhost", "https://app.slapfive.com/api", "https://gql.slapfive.com", "https://gql.stageslapfive.com"],
        routingInstrumentation: instrumentAngularRouting,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
    // before_send optional callback function that allows you to modify the event before it is sent to Sentry.
    beforeSend(event, hint) {
      // Get the exception that caused the event
      const exception = event.exception;
      // Check for a ChunkLoadError and reload the page without sending an error report to Sentry
      if (exception.values[0].type === 'ChunkLoadError') {
        console.log('Detected ChunkLoadError');
        event.extra = {
          routerAvailable: !!this.router
        };
        if (this.router) {
          const currentRoute = this.router.url || '/';
          this.router.navigate([currentRoute]);
          return null;
        } else {
          console.log('Router not available');
        }
      }
      return event;
    }
  });
}


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
  ],
  imports: [
    AuthModule.forRoot({
      domain: environment.auth0CustomDomain,
      clientId: environment.auth0ClientID,
      cacheLocation: 'memory',
      useCookiesForTransactions: false,
      useRefreshTokens: true,
      leeway: 120,     // allow for 2 mins of clock skew
      audience: environment.auth0Audience,
      httpInterceptor: {
        allowedList: [
          {
            uriMatcher: (uri) => uri.indexOf('/api/') > -1,
            allowAnonymous: true
          },
          {
            uriMatcher: (uri) => window.location.href.indexOf('/b/') === -1 && uri.indexOf('/graphql') > -1,
            allowAnonymous: true
          }
        ]
      }
    }),

    BrowserModule.withServerTransition({ appId: 'slapfive' }),
    AppRoutingModule,
    RequestFormModule,
    RequestFulfillFormModule,
    FormsModule,
    HttpClientModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    RatingModule.forRoot(),
    PaginationModule.forRoot(),
    DragulaModule.forRoot(),
    TextboxModalModule,
    BrowserAnimationsModule,
    UserIdleModule.forRoot({ idle: environment.auth0SessionWarningSeconds, timeout: environment.auth0SessionTimeoutSeconds }),
    QuillModule.forRoot(),
    MonacoEditorModule.forRoot(),
    NgxChartsModule,
    ApolloModule,
    InfiniteScrollModule,
    NgSelectModule
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: loadUser,
    //   deps: [AuthService, CurrentUserService],
    //   multi: true
    // },
    QuillModule,
    RequestService, // this has to be here because RequestCreateEditModal is here; this is because of ngx-bootstrap bug: https://github.com/valor-software/ngx-bootstrap/issues/2356
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthService]
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
        logErrors: true
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

