import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class DeleteRequestMemberMappingGqlService extends Mutation{

  document = gql`
    mutation ($requestId: String!) {
      delete_RequestFulfillmentCustomers(
        where: {
          _or: [
            {request_id: { _eq: $requestId }},
            {id: {_eq: $requestId}}
          ]
        }
      ) {
        affected_rows
      }
    }
  `;
}
