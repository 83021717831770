import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 's5-spinny-save-button',
  templateUrl: './spinny-save-button.component.html',
  styleUrls: ['./spinny-save-button.component.scss']
})
export class SpinnySaveButtonComponent implements OnInit {

  @Input() saving: boolean = false;
  @Input() disabled: boolean = false;
  @Input() title = { ready: 'Save', doing: 'Saving' }

  constructor() { }

  ngOnInit() {
  }

  get saveMsg() {
    return this.saving ? this.title.doing : this.title.ready;
  }

}
