import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class UpdateDynamicFieldSortService extends Mutation {

  document = gql`
  mutation (
    $id: String!
    $sort: Int
  ) {
    update_DynamicField(
      where: {
        id: {_eq: $id}
      },
      _set: {
        sort: $sort
      }
    ) {
      affected_rows
    }
  }
  `;
}
