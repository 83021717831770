import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { Customer } from '../member.model';
import { Company } from '../../companies/company.model';

export const MEMBER_OR_COMPANY_LIST_PICKER_QUERY_NAME = 'MemberOrCompanyListPickerQuery';



export interface MemberOrCompanyListPickerQueryResults {
  members: [Customer];
  companies: [Company];
  companyTotal: {
    aggregate: {
      count: number;
    }
  }
  memberTotal: {
    aggregate: {
      count: number;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ListMembersOrCompaniesPickerGqlServiceService extends Query<MemberOrCompanyListPickerQueryResults> {


  document = gql`
    query ${MEMBER_OR_COMPANY_LIST_PICKER_QUERY_NAME} (
      $limit: Int!,
      $memberOffset: Int!,
      $companyOffset: Int!,
      $search: String!
      ) {

      members: Customer(
        limit: $limit,
        offset: $memberOffset,
        order_by: { lname: asc },
        where: { _or: [
          { name: { _ilike: $search } },
          { company: { name: { _ilike: $search } } }
        ]}
      ) {
        id
        name
        avatarImage {
          id
          url
          mimeType
        }
        company {
          id
          name
        }
      }

      companies: Company(
        limit: $limit,
        offset: $companyOffset,
        order_by: { name: asc },
        where: { name: { _ilike: $search } }
      ) {
        id
        name
      }


      memberTotal: Customer_aggregate(
        where: { _or: [
          { name: { _ilike: $search } },
          { company: { name: { _ilike: $search } } }
        ]}
      ) {
        aggregate {
          count
        }
      }

      companyTotal: Company_aggregate(where: { name: { _ilike: $search } }) {
        aggregate {
          count
        }
      }

    }
  `;
}