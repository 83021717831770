// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ssMain {
  position: relative;
  width: 100%;
}

.ssMain .fa-times {
  color: var(--color-warn);
  right: 30px;
}

.ssSuggestions {
  position: absolute;
  top: 42px;
  overflow-y: auto;
  color: var(--grey-mddk);
  border-radius: 3px;
  padding: 0;
  background-color: var(--white);
  width: 100%;
  max-height: 18em !important;
  border: 1px solid var(--border-grey);
  z-index: 100;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.ssSuggestions ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0px;
  margin-bottom: 0;
}

.ssSuggestions ul li {
  padding: 6px !important;
  font-size: 0.9em;
  border-bottom: 1px solid var(--border-grey);
  transition: var(--trans);
  cursor: pointer;
}

.ssSuggestionsActive {
  background-color: var(--primary);
  color: var(--white);
}

.inner-addon {
  position: relative;
}

.inner-addon .fa {
  position: absolute;
  padding: 10px;
}

.right-addon .fa-times {
  color: var(--color-warn);
  right: 0px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
