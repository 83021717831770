import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Customer } from '../member.model';

import { ListMembersPickerGqlService } from '../members-gql/list-members-picker-gql.service';
import { take } from 'rxjs';

@Component({
  selector: 's5-member-picker',
  templateUrl: './member-picker.component.html',
  styleUrls: ['./member-picker.component.scss']
})
export class MemberPickerComponent {

  @Input() member: Customer;
  @Output() memberChange: EventEmitter<Customer> = new EventEmitter<Customer>();

  isLoading = false;
  members: Customer[] = [];

  membersBuffer = [];
  bufferSize = 50;
  numberOfItemsFromEndBeforeFetchingMore = 10;
  total = 0;
  searchVal = '';

  constructor(
    private listMembersPickerGqlService: ListMembersPickerGqlService
  ) {
  }

  ngOnInit() {
    if (!this.member?.id) {
      delete this.member;
    }
    this.fetchMore()
  }

  onScrollToEnd() {
    this.fetchMore();
  }

  onScroll({ end }) {
    if (this.isLoading || this.members.length <= this.membersBuffer.length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.membersBuffer.length) {
      this.fetchMore();
    }
  }

  onChange(event) {
    this.memberChange.emit(event);
    this.searchVal = '';
  }

  private fetchMore() {
    const len = this.membersBuffer.length;
    this.isLoading = true;

    const vars = {
      offset: len,
      limit: this.bufferSize,
      search: `%${this.searchVal}%`,
    };
    this.listMembersPickerGqlService.watch(vars).valueChanges
    .pipe(take(1))
    .subscribe(({ data }) => {
      this.members = JSON.parse(JSON.stringify(data.members)).map((m: any) => {
        m.label = `${m.name} ${m.company?.name || ''}`
        return m;
      });
      this.total = data.total.aggregate.count;
      this.membersBuffer = this.membersBuffer.concat(this.members);
      this.isLoading = false;
    })
  }

  onSearch(searchVal) {
    this.members = [];
    this.membersBuffer = [];
    this.searchVal = searchVal.term;
    this.fetchMore();
  }

  onClear(event) {
    this.searchVal = '';
    this.members = [];
    this.membersBuffer = [];
    this.fetchMore();
  }


}
