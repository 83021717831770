import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class RemoveStrategicInitiativeAssetGqlService extends Mutation {

  document = gql`
    mutation(
      $assetId: String!
      $strategicInitiativeId: String!
    ) {
      delete_StrategicInitiative_Asset_by_pk(
          asset_id: $assetId
          strategic_initiative_id: $strategicInitiativeId
      ) {
          asset_id
          strategic_initiative_id
        }
    }`;

}
