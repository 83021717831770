import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';


// Using this comment as an example,
//   https://github.com/hasura/graphql-engine/issues/1573#issuecomment-841790547
// we are effectively doing a 'nested entity upsert' of the dynamic field to entity
// mapping and at the same time, setting/updating the value.  So this single mutation
// will 1) create a mapping between an entity and a dynamic field, and also 2) set/update
// the current value for that dynamic field.  Note this approach does not support
// delete, but that's ok because we rely on cascading deletes to delete these mappings
// should an admin delete a dynamic field configuration.
//
// tldr; Entity -> DynamicFieldDataMapping -> DyanmicFieldData built here

@Injectable({
  providedIn: 'root'
})

export class SaveDynamicFieldValueService extends Mutation {


  document = gql`
    mutation (
      $dynamicFieldId: String!
      $entityId: String!
      $dynamicFieldDataId: String!
      $dynamicFieldDataMappingId: String!
      $value: String!
    ) {
      insert_DynamicFieldDataMapping_one(
        on_conflict: { constraint: DynamicFieldDataMapping_dynamic_field_id_entity_id_key, update_columns: [dynamic_field_id] }
        object: {
          id: $dynamicFieldDataMappingId
          dynamic_field_id: $dynamicFieldId
          entity_id: $entityId
          dynamicFieldData: {
            data: {
              id: $dynamicFieldDataId
              value: $value
            }
            on_conflict: { constraint: DynamicFieldData_pkey, update_columns: [value] }
          }
        }
      ) {
        id
      }
    }
    `;

}
