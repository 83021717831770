import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class UpsertExternalContactGqlService extends Mutation {

  document = gql`

    mutation (
        $id: String!
        $client_id: String!
        $externalContact_id: String!
        $name: String
        $title: String
        $email: String
        $account_id: String
        $companyName: String
    ) {
      insert_ExternalContact_one(
        object: {
          id: $id
          client_id: $client_id
          externalContact_id: $externalContact_id
          name: $name
          title: $title
          email: $email
          account_id: $account_id
          companyName: $companyName
        },
        on_conflict: {
          constraint: ExternalContact_pkey,
          update_columns: [
            client_id, externalContact_id, name, title, email, account_id, companyName
          ]
        }
      ) {
        id client_id externalContact_id name title email account_id companyName
      }
    }
  `;
}
