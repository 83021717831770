import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

// used by the admin tool settings to delete a pick list value from the
//  configuration of a dynamic field which uses pick lists

@Injectable({
  providedIn: 'root'
})
export class DeleteDynamicFieldPickValueService extends Mutation {

  document = gql`
    mutation (
      $id: String!
    ) {
      delete_DynamicFieldData(
        where: {
          value: {_eq: $id }}
      ) {
        affected_rows
      }

      delete_DynamicFieldPickListValue_by_pk(
        id: $id
      ) {
        id
      }
    }
    `;

}
