import {
  Component,
  Input,
  AfterContentInit,
} from '@angular/core';
import { Customer } from 'app/+client-admin/members/member.model';
import { Story } from 'app/+client-admin/stories/story.model';
import { Utils } from 'app/shared/utils/Utils';

@Component({
  selector: 's5-request-story-card',
  templateUrl: './request-story-card.component.html',
  styleUrls: ['./request-story-card.component.scss']
})
export class RequestStoryCardComponent implements AfterContentInit {

  @Input() public story: Story;
  
  @Input() public set customer(c: Customer) {
    this._customer = c;
    this.customerImage = Utils.customerAvatar(this.customer);
    this.companyImage = Utils.customerCompanyImage(this.customer);
  }
  public get customer() {
    return this._customer;
  }
  public _customer: Customer;

  @Input() public index: number = 1;
  @Input() public autoLaunch = false;
  @Input() public btnSize = '';
  @Input() public tooltip = 'Preview Detail';
  @Input() public squaredCircle = false;
  @Input() public iconWeight = 'fas';

  useIcon: string;
  customerImage: string;
  companyImage: string;
  stories: Story[];

  constructor() { }

  ngAfterContentInit(): void {
    this.useIcon = `${ this.iconWeight } ${ Utils.storyTypeIconClass(this.story.type).split(' ')[1] }`;
    this.customerImage = Utils.customerAvatar(this.customer);
    this.companyImage = Utils.customerCompanyImage(this.customer);
    this.stories = [this.story]
  }

}

