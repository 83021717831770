import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'storyConfigContainerFilter'
})
export class StoryConfigContainerFilterPipe implements PipeTransform {
  transform(sscs, filterVal) {
    return sscs.filter(ssc =>
      !filterVal || filterVal.length === 0 ||
      ssc.customer.name.toLowerCase().indexOf(filterVal) > -1 ||
      ssc.customer.title.toLowerCase().indexOf(filterVal) > -1 ||
      ssc.customer.company.toLowerCase().indexOf(filterVal) > -1 ||
      ssc.prompt.title.toLowerCase().indexOf(filterVal) > -1 ||
      (!!ssc.story.text && ssc.story.text.toLowerCase().indexOf(filterVal) > -1)
    );
  }
}
