import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { User } from '../user.model';
import { Query } from 'apollo-angular';

export const LIST_USERS_QUERY_NAME = 'ListUsersQuery';

interface ListUsersQueryResult {
  current_user: [User];
}


@Injectable({
  providedIn: 'root'
})
export class ListUsersGqlService extends Query<ListUsersQueryResult> {

  document = gql`
    query ${LIST_USERS_QUERY_NAME} {
      current_user {
        id
        superadmin
        currentRole {
          id
          client {
            id
            clientRoles {
              id
              role
              users {
                user {
                  id
                  name
                  email
                  emailVerified
                  auth0UserId
                  phone
                  lastLogin
                  currentRole {
                    id
                    role
                  }
                  clientRoles {
                    id
                    notifyNewPromptResponse
                    notifyNewRequests
                    allowRequestAssignment
                    prefilterMembers
                    auth0UserId
                    ClientRole {
                      id
                      role
                      client {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`;
}
