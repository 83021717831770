import { Component, EventEmitter, Input, Output, OnInit, AfterViewInit, HostListener } from '@angular/core';
import cuid from 'cuid';


@Component({
  selector: 'filter',
  styleUrls: ['./filter.component.scss'],
  templateUrl: './filter.component.html',
})
export class FilterComponent implements OnInit, AfterViewInit {
  @Input() public value = '';
  @Input() public placeholder = 'Search';
  @Input() public debounce = 300;
  @Input() public newType = false;
  @Input() public width = 200;
  @Input() public widthButton = 34;
  @Output() private valueChange: EventEmitter<string> = new EventEmitter<string>();


  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
   if (event.ctrlKey && event.key == 's') { this.waxToggle(); }
   if (event.ctrlKey && event.key == 'x') { this.clearValue(); }
  }


  timer = 0;
  open = false;
  hash = cuid();
  hashButton = `${this.hash}-button`;

  public ngOnInit() {
    if (!this.value) {
      this.value = '';
    }
  }

  public ngAfterViewInit(): void {
    // setTimeout(() => {
    //   if (this.newType) {
    //     this.waxToggle();
    //   }
    // });
    setTimeout(() => {
      if (this.newType && this.value.length > 0 && !this.open) {
        this.waxToggle();
      }

      $(`#${this.hashButton}`).on('mouseover', () => {
        if (!this.open) { 
          this.waxToggle();
         }
      });
    });



  }

  public changed() {
    clearTimeout(this.timer);
    this.value = this.value.toLowerCase();
    setTimeout(() => {
      this.valueChange.emit(this.value);
    }, this.debounce);
  }

  public clearValue() {
    this.value = '';
    this.changed();
  }

  public waxToggle() {
    if (!this.open) {
      const w = this.width - 34;
      $(`#${this.hash} input`).css({ width: w});
      $(`#${this.hash}`).animate({ width: this.width}, 300);
      $(`#${this.hash} input`)[0]?.focus();
      this.open = !this.open;
    } else {
      $(`#${this.hash}`).animate({ width: 0}, 150);
      this.value = '';
      this.changed();
      this.open = !this.open;
    }
  }

}
