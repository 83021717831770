import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';



@Injectable({
  providedIn: 'root'
})
export class CreateDynamicFieldPickValueService extends Mutation {

  document = gql`
    mutation (
      $id: String!
      $name: String!
      $dynamicFieldId: String!
      $order: Int!
    ) {
      insert_DynamicFieldPickListValue_one (
        object: {
          id: $id,
          name: $name,
          dynamic_field_id: $dynamicFieldId
          order: $order
        }
      ) {
        id
      }
    }
  `;

}
