import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyPickerComponent } from './company-picker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CompanyPickerComponent
  ],
  exports: [
    CompanyPickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ]
})
export class CompanyPickerModule { }
