import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextboxModalComponent } from './textbox-modal.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [TextboxModalComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    TextboxModalComponent
  ],
})
export class TextboxModalModule { }
