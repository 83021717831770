import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';


@Injectable({
  providedIn: 'root'
})
export class RemoveStrategicInitiativeStoryGqlService extends Mutation {


  document = gql`
    mutation(
      $storyId: String!
      $strategicInitiativeId: String!
    ) {
      delete_StrategicInitiative_Story_by_pk(
        story_id: $storyId
        strategic_initiative_id: $strategicInitiativeId
      ) {
          story_id
          strategic_initiative_id
        }
    }`;

}
