import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterComponent } from './filter.component';
import { AdvancedFilterFieldComponent } from './advanced-filter-field/advanced-filter-field.component';
import { SingleSelectModule } from '../single-select/single-select.module';
import { TooltipModule  } from 'ngx-bootstrap/tooltip';


@NgModule({
  imports: [
    CommonModule, 
    FormsModule, 
    SingleSelectModule, 
    TooltipModule.forRoot()
  ],
  declarations: [FilterComponent, AdvancedFilterFieldComponent],
  exports: [FilterComponent, AdvancedFilterFieldComponent]
})
export class FilterModule {

}
