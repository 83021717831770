import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UtilsModule } from '../../shared/utils/utils.module';
import { SingleSelectFilterPipe } from './single-select-filter.pipe';
import { SingleSelectComponent } from './single-select.component';

@NgModule({
  declarations: [
    SingleSelectComponent,
    SingleSelectFilterPipe,
  ],
  exports: [
    SingleSelectComponent,
    SingleSelectFilterPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    UtilsModule,
  ],
})
export class SingleSelectModule {

}
