import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestFormComponent } from './request-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DynamicFieldsModule } from 'app/+client-admin/dynamic-fields/dynamic-fields.module';
import { InfiniteSelectModule } from 'app/shared/infinite-select/infinite-select.module';
import { StrategicInitiativesModule } from '../../strategic-initiatives/strategic-initiatives.module';

@NgModule({
  declarations: [
    RequestFormComponent
  ],
  exports: [
    RequestFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DynamicFieldsModule,
    InfiniteSelectModule,
    StrategicInitiativesModule
  ]
})
export class RequestFormModule { }
