import { Injectable } from '@angular/core';
import { Subscription } from 'apollo-angular';

import gql from 'graphql-tag';
import { DynamicFieldPickListValue } from '../dynamic-field.model';

// exported for cache reference; must be unique across s5
export const LIST_DYNAMIC_FIELD_ENTITY = "ListDynamicFieldPickListValue";

export interface DynamicFieldPickListValueResult {
  DynamicFieldPickListValue: [DynamicFieldPickListValue]
}


@Injectable({
  providedIn: 'root'
})
export class ListDynamicFieldPickValuesService extends Subscription<DynamicFieldPickListValueResult> {

  document = gql`
    subscription ${LIST_DYNAMIC_FIELD_ENTITY} ($dynamicFieldId: String){
      DynamicFieldPickListValue(where: { dynamic_field_id: { _eq: $dynamicFieldId }}, order_by: [{order: asc}, {name: asc}]) {
        id
        name
        order
      }
    }
  `;

}
