// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.traySetHeight {
  height: 0;
}

.appPageTray-holder {
  position: fixed;
  top: 0;
  width: 0;
  right: 0;
  z-index: 1299;
  transition: all 0.3s ease-in-out;
}
.appPageTray-holder h1, .appPageTray-holder h2, .appPageTray-holder h3, .appPageTray-holder h4, .appPageTray-holder h5 {
  font-family: var(--f-heading);
}
.appPageTray-holder .toggleSwitch {
  color: var(--grey-lite);
  font-size: 30px;
  cursor: pointer;
  transition: 0.3s;
}
.appPageTray-holder .toggleSwitch:hover, .appPageTray-holder .toggleSwitch.selected {
  color: var(--primary);
}
.appPageTray-holder .scroller {
  overflow-y: scroll;
}
.appPageTray-holder .appPageTray {
  background-color: white;
  position: relative;
  box-shadow: -3px 0px 6px rgba(0, 0, 0, 0);
  transition: 0.3s;
}
.appPageTray-holder .appPageTray .appPageTray__title {
  font-family: var(--f-heading);
}
.appPageTray-holder .appPageTray .appPageTray__title .trayTitleCustom:empty {
  display: none;
}
.appPageTray-holder .appPageTray .appPageTray__title h3 {
  font-size: var(--font-size-24);
  font-weight: var(--f-600);
  color: var(--grey-3c3c3c);
  max-width: 75%;
  margin: 0;
}
.appPageTray-holder .appPageTray > div {
  padding: 20px;
}
.appPageTray-holder__show .appPageTray {
  box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.15);
}

.appPageInnerTray-holder {
  display: block;
  position: absolute;
  width: 0;
  top: 0;
  right: 342px;
  transition: all 0.3s ease-in-out;
  box-shadow: -3px 0px 6px rgba(0, 0, 0, 0);
  background-color: white;
  z-index: -1;
  overflow-y: scroll;
}

.appPageInnerTray-holder__show {
  box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.15);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
