import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableSortComponent } from './table-sort';

@NgModule({
  imports: [CommonModule],
  declarations: [TableSortComponent],
  exports: [TableSortComponent]
})
export class TableSortModule {

}
