import { Injectable } from '@angular/core';

import gql from 'graphql-tag';
import { Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class UpdateTagService extends Mutation {
  document = gql`
  mutation (
    $id: String!
    $client_id: String!
    $name: String!
    $description: String
    $color: String!
  ) {
    update_Tag(
      where: {
        id: {_eq: $id}
      },
      _set: {
        client_id: $client_id
        name: $name
        description: $description
        color: $color
      }
    ) {
      affected_rows
    }
  }
  `;
}
