import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 's5-textbox-modal',
  templateUrl: './textbox-modal.component.html',
  styleUrls: ['./textbox-modal.component.scss']
})
export class TextboxModalComponent implements OnInit {


  @Output() action = new EventEmitter<String>();
  @Input('textfield') textfield: string = '';

  constructor(public bsModalRef: BsModalRef) { }

  title: string = '';

  label: string = '';
  maxlength: number;

  ngOnInit() {
  }


  public clickSave() {
    this.action.emit(this.textfield);
    this.bsModalRef.hide()
  }

}
