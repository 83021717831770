import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    const keys = [];
    for (const enumMember in value) {
      const isValueProperty = parseInt(enumMember, 10) >= 0;
      if (isValueProperty) {
        keys.push({ key: enumMember, value: value[enumMember] });
      }

      // for (let key in value) {
      //  keys.push({key: key, value: value[key]);
    }
    return keys;
  }
}
