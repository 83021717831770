// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 30px;
  line-height: 26px;
  padding: 2px 10px 2px 13px;
  position: relative;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.icon::before {
  /* removed font- based rules */
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.tag::before {
  /* using style CSS custom property */
  font: var(--fa-font-solid);
  content: "\\f02b";
  padding-inline-end: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
