import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActivityType } from '../activity-type.model';

import { ActivityTypePickerGqlService } from '../activities-gql/activity-type-picker-gql.service';
import { take } from 'rxjs';

@Component({
  selector: 's5-activity-type-picker',
  templateUrl: './activity-type-picker.component.html',
  styleUrls: ['./activity-type-picker.component.scss']
})
export class ActivityTypePickerComponent {

  @Input() activityType?: ActivityType;
  @Output() activityTypeChange: EventEmitter<ActivityType> = new EventEmitter<ActivityType>();

  isLoading = false;
  activityTypes: ActivityType[] = [];

  // activityTypesBuffer = [];
  // bufferSize = 50;
  // numberOfItemsFromEndBeforeFetchingMore = 10;
  total = 0;
  // searchVal = '';

  constructor(
    private listActivityTypesPickerGqlService: ActivityTypePickerGqlService
  ) {
  }

  ngOnInit() {
    if (!this.activityType?.id) {
      delete this.activityType;
    }
    this.fetchMore()
  }

  onScrollToEnd() {
    // this.fetchMore();
  }

  onScroll({ end }) {
    // if (this.isLoading || this.activityTypes.length <= this.activityTypesBuffer.length) {
    //   return;
    // }

    // if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.activityTypesBuffer.length) {
    //   this.fetchMore();
    // }
  }

  onChange(event) {
    this.activityTypeChange.emit(event);
    // this.searchVal = '';
  }

  private fetchMore() {
    // const len = this.activityTypesBuffer.length;
    this.isLoading = true;

    // const vars = {
    //   offset: len,
    //   limit: this.bufferSize,
    //   search: `%${this.searchVal}%`,
    // };
    this.listActivityTypesPickerGqlService.watch({}).valueChanges
    .pipe(take(1))
    .subscribe(({ data }) => {
      this.activityTypes = JSON.parse(JSON.stringify(data.ActivityType));
      this.total = this.activityTypes.length;
      this.isLoading = false;
    })
  }

  onSearch(searchVal) {
    // this.activityTypes = [];
    // this.searchVal = searchVal.term;
    // this.fetchMore();
  }

  onClear() {
    // this.searchVal = '';
    // this.activityTypes = [];
    // this.fetchMore();
  }


}
