import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class AddStrategicInitiativePromptGqlService extends Mutation {


  document = gql`
    mutation(
      $promptId: String!
      $strategicInitiativeId: String!
    ) {
      insert_StrategicInitiative_Prompt_one(
        object: {
          prompt_id: $promptId,
          strategic_initiative_id: $strategicInitiativeId
        }
      ) {
          prompt_id
          strategic_initiative_id
        }
    }`;


}
