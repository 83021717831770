import { Component, OnInit } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { DynamicFieldModel, DynamicFieldEntityModel } from '../dynamic-field.model';

import { DeleteDynamicFieldService } from '../dynamic-fields-gql/delete-dynamic-field.service';
import { ListAllDynamicFieldsService } from '../dynamic-fields-gql/list-all-dynamic-fields.service'
import { UpdateDynamicFieldSortService } from '../dynamic-fields-gql/update-dynamic-field-sort.service'
import { ActivatedRoute } from '@angular/router';
import { CreatePromptGqlService } from 'app/+client-admin/prompts/prompts-gql/create-prompt-gql.service';

@Component({
  selector: 's5-dynamic-fieldset-configure',
  templateUrl: './dynamic-fieldset-configure.component.html',
  styleUrls: ['./dynamic-fieldset-configure.component.scss']
})
export class DynamicFieldsetConfigureComponent implements OnInit {

  dynamicField$:Observable<DynamicFieldEntityModel[]>;
  newDynamicField:DynamicFieldModel;
  globalAdmin = false;
  id: string;

  constructor(
    private listAllDynamicFieldsService: ListAllDynamicFieldsService,
    private updateDynamicFieldSortService: UpdateDynamicFieldSortService,
    private deleteDynamicFieldService: DeleteDynamicFieldService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.globalAdmin = this.route.snapshot.data.globalAdmin;
    this.id = this.route.snapshot.params['id'];

    if (this.globalAdmin && !this.id) {
      this.dynamicField$ = of([]);
    } else {
      this.dynamicField$ = this.listAllDynamicFieldsService.subscribe()
      .pipe(map(({ data }) => {
        const sortUpdatesRequired = [];
        for (const entity of data.DynamicFieldEntity) {
          let i = 0;
          for (const field of entity.dynamicFields) {
            if (field.sort != i) {
              sortUpdatesRequired.push({ id: field.id, sort: i });
            }
            i = i + 2;
          }
        }
        for (const update of sortUpdatesRequired) {
          this.updateDynamicFieldSortService.mutate(update).toPromise();
        }
        return data.DynamicFieldEntity;
      }));
    }
  }


  add() {
    this.newDynamicField = {
      label:'',
      inlineEdit:false,
      type: "TEXT",
      entityName: "ASSET",
      description: '',
      flag: true
    };
  }

  edit(df:DynamicFieldModel) {
    df.inlineEdit = true;
  }

  async moveDfUp(df: DynamicFieldModel) {
    await this.updateDynamicFieldSortService.mutate({ id: df.id, sort: (df.sort || 0) - 3 }).toPromise();
    console.log("done");
  }

  async moveDfDown(df: DynamicFieldModel) {
    await this.updateDynamicFieldSortService.mutate({ id: df.id, sort: (df.sort || 0) + 3 }).toPromise();
  }

  async delete(df:DynamicFieldModel) {
    if (confirm("Are you sure you want to delete this Dynamic Field?")) {
      console.log("Deleting dyanmic field");
      await this.deleteDynamicFieldService.mutate({ id: df.id }).toPromise();
    }

  }

}
