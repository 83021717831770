import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | more:wordlength
 * Example:
 *   {{ "the quick brown fox jumeped over the lazy dog" |  more:4}}
 *   formats to: "the quick brown fox ...more"
*/
@Pipe({ name: 'more' })
export class MorePipe implements PipeTransform {
  transform(value: string, wordLength: number, link: string): string {
    const words = value.split(' ');
    let shorter = '';
    for (let i = 0; i < wordLength; i++) {
      shorter += ' ' + words[i];
    }
    shorter += ' more ';

    return shorter;
  }
}
