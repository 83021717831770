import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrategicInitiativeEditComponent } from './strategic-initiative-edit/strategic-initiative-edit.component';
import { StrategicInitiativesListComponent } from './strategic-initiatives-list/strategic-initiatives-list.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { StrategicInitiativeTagComponent } from './strategic-initiative-tag/strategic-initiative-tag.component';
import { TagsModule } from '../tags/tags.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SpinnySaveButtonModule } from 'app/shared/spinny-save-button/spinny-save-button.module';

import { StrategicInitiativeTaggerComponent } from './strategic-initiative-tagger/strategic-initiative-tagger.component';

import { UserPickerModule } from '../users/user-picker/user-picker.module';
import { Tooltip } from 'highcharts';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
@NgModule({
  declarations: [
    StrategicInitiativeEditComponent,
    StrategicInitiativesListComponent,
    StrategicInitiativeTagComponent,
    StrategicInitiativeTaggerComponent,
  ],
  exports: [
    StrategicInitiativeTaggerComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    TagsModule,
    NgSelectModule,
    AlertModule,
    SpinnySaveButtonModule,
    UserPickerModule,
    TooltipModule.forRoot()
  ]
})
export class StrategicInitiativesModule { }
