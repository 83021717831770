import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class AddStrategicInitiativeStoryGqlService extends Mutation {


  document = gql`
    mutation(
      $storyId: String!
      $strategicInitiativeId: String!
    ) {
      insert_StrategicInitiative_Story_one(
        object: {
          story_id: $storyId,
          strategic_initiative_id: $strategicInitiativeId
        }
      ) {
          story_id
          strategic_initiative_id
        }
    }`;

}
