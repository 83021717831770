import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'propertyFilter' })
export class PropertyFilter implements PipeTransform {
  public transform(objectsIn: any[], filterVal: string, properties: string[], advancedFilters: any, trigger: boolean): any[] {
    let objects = objectsIn;
    if (filterVal && filterVal.length > 0) {
      objects = objects.filter((ob) => {
        return properties.filter((prop) => {
          const ret = this.getVal(ob, prop).toLowerCase().indexOf(filterVal.toLowerCase()) > -1;
          return ret;
        }).length > 0;
      });
    }
    if (advancedFilters) {
      for (const filterSet of advancedFilters) {
        {
          for (const filter of filterSet) {
            if (filter && filter.val && filter.val.length > 0) {
              objects = objects.filter((ob) => {
                let found = false;
                const obVals = this.getVals(ob, filter.key);
                const searchVal = filter.val.toLowerCase();
                for (const obVal of obVals) {
                  if (filter.requireEquals && obVal && obVal.toLowerCase() == searchVal) {
                    found = true;
                  } else if (!filter.requireEquals && obVal && obVal.toLowerCase().indexOf(searchVal) > -1) {
                    found = true;
                  }
                }
                return found;
              });
            } else if (filter && filter.vals && filter.vals.length > 0) {
              objects = objects.filter((ob) => {
                const obVals = this.getVals(ob, filter.key);
                const searchVals = filter.vals.map((v) => v.toLowerCase());
                for (const searchVal of searchVals) {
                  let found = false;
                  for (const obVal of obVals) {
                    if (obVal && obVal.toLowerCase().indexOf(searchVal) > -1) {
                      found = true;
                    }
                  }
                  if (!found) {
                    return false;
                  }
                }
                return true;
              });
            }
          }
        }
      }
    }
    return objects;
  }

  private getVal(item, props) {
    let i = item;
    for (const k of props.split('.')) {
      if (i) {
        i = i[k];
      }
    }
    if (i) {
      return i;
    }
    return '';
  }

  public getVals(item, props) {
    let i = item;
    let p = props;
    while (p && p.trim().length > 0 && i) {
      const split = p.split(/\.(.+)/);
      let thisKey = split[0];
      const nextKeys = split[1];
      if (thisKey.endsWith('[]')) {
        thisKey = thisKey.slice(0, -2);
        let ret = [];
        for (const subItem of i[thisKey]) {
          ret = ret.concat(this.getVals(subItem, nextKeys));
        }
        return ret;
      } else {
        i = i[thisKey];
      }
      p = nextKeys;
    }
    return new Array().concat([i]);
  }
}
