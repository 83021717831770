import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { StrategicInitiativeModel } from '../strategic-initiative.model';
import { Client } from 'app/+client-admin/clients/client.model';

export const VIEW_STRATEGIC_INITITATIVES_QUERY_NAME = 'ViewStrategicInitiativesQuery';

export interface ViewPromptsGqlResult {
  strategicInitiative: StrategicInitiativeModel;
  Client: Client;
}

@Injectable({
  providedIn: 'root'
})
export class StrategicInitiativeViewGqlService extends Query<ViewPromptsGqlResult> {

  document = gql`
  query ${VIEW_STRATEGIC_INITITATIVES_QUERY_NAME}($id: String!)  {

    strategicInitiative: StrategicInitiative_by_pk(id: $id ) {
      id
      name
      color
      description
      executiveSponsor
      goal
      influence
      startDt
      endDt
      owner {
        id
        name
        avatarImage {
          id
          url
        }
      }
      activities_aggregate {
        aggregate {
          count
        }
      }
    	assets_aggregate {
        aggregate {
          count
        }
      }
    	boards_aggregate {
        aggregate {
          count
        }
      }
    	prompts_aggregate {
        aggregate {
          count
        }
      }
    	requests_aggregate {
        aggregate {
          count
        }
      }
    	stories_aggregate {
        aggregate {
          count
        }
      }
    }
    Client {
      strategicInitiatives { id name }
    }
  }
`;

}
