import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class RemoveStrategicInitiativeBoardGqlService extends Mutation {


  document = gql`
    mutation(
      $boardId: String!
      $strategicInitiativeId: String!
    ) {
      delete_StrategicInitiative_Board_by_pk(
        board_id: $boardId
        strategic_initiative_id: $strategicInitiativeId
      ) {
          board_id
          strategic_initiative_id
        }
    }`;

}
