import { Component, Input, OnInit } from '@angular/core';

import { User } from '../../user.model';

@Component({
  selector: 's5-user-tag',
  templateUrl: './user-tag.component.html',
  styleUrls: ['./user-tag.component.scss']
})
export class UserTagComponent implements OnInit {

  @Input() user: User;
  @Input() showAvatar: boolean = true;
  @Input() showRole: boolean = true;

  avatarUrl = '/assets/img/blank_avatar.jpg';

  constructor() { }

  ngOnInit(): void {
    if (this.user?.avatarImage) {
      this.avatarUrl = this.user.avatarImage.url;
    }
  }


}
