import { Injectable } from '@angular/core';
import { Client } from '../../clients/client.model';
import { CampaignModel } from '../campaign-model';

import { Query } from 'apollo-angular';
import gql from 'graphql-tag';


export const EDIT_CAMPAIGN_QUERY_NAME = 'EditCampaignQuery';


export interface EditCampaignQueryResult {
  campaign: CampaignModel;
  Client: Client;
}


@Injectable({
  providedIn: 'root'
})
export class EditCampaignGqlService extends Query<EditCampaignQueryResult>{


  document = gql`
  query ${EDIT_CAMPAIGN_QUERY_NAME}($id: String!)  {

    campaign: Campaign_by_pk(id: $id ) {
      id
      name
      description
      startDt: start_dt
      endDt: end_dt
      owner {
        id
        name
        avatarImage {
          id
          url
        }
      }
      goal
      completed
      strategicInitiatives {
        strategic_initiative_id
        campaign_id
        strategicInitiative {
          id
          name
          color
        }
      }
      client {
        id
      }
    }
  }
`;

}


