import { Component, OnInit, Input } from '@angular/core';
import { Customer } from '../member.model';
import { Utils } from '../../../shared/utils/Utils';
import { TrayService } from '../../../shared/trays/tray.service';
import { Company } from 'app/+client-admin/companies/company.model';
import { User } from 'app/+client-admin/users/user.model';
import { ClientRole } from 'app/+client-admin/clients/client-role.model';
import { UserService } from 'app/+client-admin/users/user.service';
import { Client } from 'app/+client-admin/clients/client.model';
import { Router } from '@angular/router';

@Component({
  selector: 's5-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.scss']
})
export class CustomerCardComponent implements OnInit {


  @Input() customer: Customer;
  @Input() newTable: false;
  @Input() useTray: false;
  avatar: any;
  client: Client;
  permissions;
  
  profileTray: any = {
    trayWidth: 600,
    trayWidthInner: 500,
    data: {}
  }

  constructor(
    private trayService: TrayService,
    private userService: UserService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.permissions = await Utils.getRolePermissions(this.userService);
    if (this.customer.__typename === 'Company') {
      this.avatar = Utils.companyImage(this.customer as Company)
    } else {
      this.avatar = Utils.customerAvatar(this.customer);
    }
    const currentUser = await this.userService.getUser();
    this.client = currentUser.currentRole.client;

  }

  setupTray() {
    if (this.useTray || this.customer.__typename === 'User') {
      if (false && this.trayService.tray) {
        this.profileTray = this.trayService.tray;
        this.profileTray.trayWidthInner = 500; 
        if (!this.profileTray.data) {
          this.profileTray.data = {};
        }
      } else {
        this.profileTray.id = "memberCard";
      }
    } else if (this.customer.__typename === 'Customer') {
      this.router.navigate(['/client/members/view', this.customer.id]);
    } else if (this.customer.__typename === 'Company') {
      this.router.navigate(['/client/companies/view', this.customer.id]);
    }
  }

  toggleUserTray() {
    this.setupTray();
    // this.trayService.closeInnerTray(this.profileTray);
    const userRoleMap = {};
    this.client.clientRoles.forEach((role: ClientRole) => {
      const foundRole = (this.customer as User).clientRoles?.find(cr => cr.ClientRole.id === role.id);
      userRoleMap[role.id] = {
        role: role,
        userRole: foundRole || {},
        enabled: !!foundRole,
      };
    });
    
    this.profileTray = {
      ...this.profileTray,
      innerTrayId: 'userProfileTray',
      innerTrayTitle: 'User Profile',
      trayTitleTooltip: 'User Profile',
      data: {
        ...this.profileTray.data,
        user: this.customer,
        member: null,
        userRoleMap: userRoleMap,
        innerCheckId: this.customer.id,
        client: this.client,
        readonly: true
      }
    }
    this.trayService.toggleInnerTray(this.profileTray);
  }

  toggleMemberTray() {
    this.setupTray();
    if (this.customer.__typename === 'Customer' && !this.permissions.member_edit) {
      console.log("User does not have permission to view members");
      return;
    }
    if (this.customer.__typename === 'Company' && !this.permissions.company_edit) {
      console.log("User does not have permission to view companies");
      return;
    }
    this.profileTray = {
      ...this.profileTray,
      innerTrayId: 'memberCard',
      innerTrayTitle: this.customer.__typename === 'Customer' ? 'Member Profile' : 'Company Profile',
      data: {
        ...this.profileTray.data,
        member: this.customer,
        innerCheckId: this.customer.id,
        profile: true,
        client: this.client
      }
    }
    this.profileTray.innerTrayTitleTooltip = this.profileTray.innerTrayTitle;

    this.trayService.toggleInnerTray(this.profileTray);
  }

}

