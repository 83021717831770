import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { Customer } from '../member.model';
import { Company } from '../../companies/company.model';

import { ListMembersOrCompaniesPickerGqlServiceService } from '../members-gql/list-members-or-companies-picker-gql.service.service';
import { debounceTime, take } from 'rxjs';
import { Utils } from 'app/shared/utils/Utils';
import { FormControl } from '@angular/forms';


@Component({
  selector: 's5-member-or-company-picker',
  templateUrl: './member-or-company-picker.component.html',
  styleUrls: ['./member-or-company-picker.component.scss']
})
export class MemberOrCompanyPickerComponent {

  @ViewChild('MemberOrCompanyPicker', { read: ElementRef }) MemberOrCompanyPicker: ElementRef;
  @Input() memberOrCompany: Customer|Company;
  @Output() memberOrCompanyChange: EventEmitter<Customer|Company> = new EventEmitter<Customer|Company>();

  searchControl = new FormControl('');
  
  isLoading = false;
  isCollapsed = true;

  membersAndCompanies:(Company|Customer)[] = [];
  membersAndCompaniesBuffer:(Company|Customer)[] = [];
  

  // Needed tp track members and companies separately
  members: Customer[] = [];
  membersBuffer: Customer[] = [];
  member: Customer;

  companies: Company[] = [];
  companiesBuffer: Company[] = [];
  company: Company;

  bufferSize = 20;
  numberOfItemsFromEndBeforeFetchingMore = 10;
  total = 0;
  searchVal = '';

  constructor(
    private listMembersOrCompaniesPickerGqlService: ListMembersOrCompaniesPickerGqlServiceService
  ) {
  }

  ngOnInit() {
    if (!this.memberOrCompany?.id) {
      delete this.memberOrCompany;
    } else if (this.memberOrCompany.__typename === 'Customer') {
      this.member = this.memberOrCompany as Customer;
    } else {
      this.company = this.memberOrCompany as Company;
    }
    this.fetchMore()
  }

  onScrollMembersToEnd(event) {
    this.fetchMore();
  }

  onScrollCompaniesToEnd(event) {
    this.fetchMore();
  }

  onScroll({ end }) {
    if (this.isLoading || this.membersAndCompanies.length <= this.membersAndCompaniesBuffer.length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.membersAndCompaniesBuffer.length) {
      this.fetchMore();
    }
  }

  onBlur() {
    setTimeout(() => {
      this.isCollapsed = true;
    }, 200);
  }

  onKey(event: any) {
    this.searchVal = event.target.value;
    this.fetchMore();
  }

  onChange(event) {
    this.memberOrCompany = event;
    this.memberOrCompanyChange.emit(event);
    this.searchVal = '';
    
    if (!event) {
      delete this.company;
      delete this.member;
      return;
    }
    
    if (event.__typename === 'Customer') {
      delete this.company;
    } else {
      delete this.member;
    }

    this.isCollapsed = true;
  }

  private fetchMore() {
    this.isLoading = true;

    const vars = {
      memberOffset: this.membersBuffer.length,
      companyOffset: this.companiesBuffer.length,
      limit: this.bufferSize,
      search: `%${this.searchVal}%`,
    };
    this.listMembersOrCompaniesPickerGqlService.watch(vars).valueChanges
      .subscribe((resp) => {
        const data = JSON.parse(JSON.stringify(resp.data));
        
        this.members = data.members;
        this.membersBuffer = this.membersBuffer.concat(this.members);
        
        this.companies = data.companies;
        this.companiesBuffer = this.companiesBuffer.concat(this.companies);

        this.membersAndCompanies = [...this.members, ...this.companies];
        this.membersAndCompanies = this.membersAndCompanies.map((m: any) => {
          m.label = `${m.name} ${m.company?.name || ''}`.trim();
          return m;
        });
        this.membersAndCompaniesBuffer = this.membersAndCompaniesBuffer.concat(this.membersAndCompanies);

        this.total = (data.memberTotal?.aggregate?.count || 0) + (data.companyTotal?.aggregate?.count || 0);
        this.isLoading = false;
      });
  }

  onSearch(searchVal) {
    this.members = [];
    this.membersBuffer = [];
    this.companies = [];
    this.companiesBuffer = [];
    this.membersAndCompanies = [];
    this.membersAndCompaniesBuffer = [];
    // this.searchVal = searchVal.term;
    this.fetchMore();
  }

  onClear($event) {
    $event.stopPropagation();
    this.memberOrCompany = null;
    this.searchVal = '';
    this.membersBuffer = [];
    this.companies = [];
    this.companiesBuffer = [];
    this.membersAndCompanies = [];
    this.membersAndCompaniesBuffer = [];
    this.isCollapsed = true;
    this.onBlur();
    this.onChange(null);
    this.fetchMore();
  }

  customerAvatar(customer: Customer): string {
    return Utils.customerAvatar(customer);
  }
}
