import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class DeleteDynamicFieldService extends Mutation {

  document = gql`
  mutation($id: String!) {
    delete_DynamicField_by_pk(id: $id) {
      id
    }
  }
  `;}
