import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { lowerCase, clone } from 'lodash';

export interface ServerSort {
  path: string;
  direction: string;
}

@Component({
  selector: 'table-sort',
  styles: ['.pointer {cursor:pointer;}'],
  template: `<span (click)="sortClicked()" class="pointer klassHoverToggle"><i [ngClass]="currentIconClasses" class="me-1"></i>{{ text }}</span>`,
})
export class TableSortComponent implements OnInit, OnChanges {

  public baseIconClasses: string[] = ['fa', 'pointer'];
  public currentIconClasses: string[];

  @Input() public values: any[];
  @Output() public valuesChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() public prop: string;
  @Input() public text: string;
  @Output() public sort: EventEmitter<any> = new EventEmitter();

  @Input() public currSort: string;
  @Output() public currSortChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() public matchCase = false;

  @Output() public serverSortChange: EventEmitter<ServerSort> = new EventEmitter<ServerSort>();

  @Input() public defaultSort = true;

  @Input() public dataType: string = 'text';

  public sortDir = true;
  public firstRun = true;

  public ngOnInit() {
    this.setIcons();
  }

  public ngOnChanges() {
    this.setIcons();
    if (this.currSort === this.prop && this.values) {
      if (this.firstRun) {
        this.sortDir = this.defaultSort;
        this.firstRun = false;
      }
      this.doSort();
    }
  }

  public setIcons() {
    // const elems = document.querySelectorAll(".handlerClass.active");
    // [].forEach.call(elems, function(el) {
    //     el.classList.remove("active");
    // });
    this.currentIconClasses = this.baseIconClasses.slice();
    if (this.currSort === this.prop) {
      this.currentIconClasses.push(this.sortDir ? 'fa-sort-up' : 'fa-sort-down');
    } else {
      this.currentIconClasses.push('fa-sort');
    }
    this.currentIconClasses.push('handlerClass');
  }

  public sortClicked() {
    if (this.currSort !== this.prop) {
      this.currSort = this.prop;
      this.sortDir = this.defaultSort;
    } else {
      this.sortDir = !this.sortDir;
    }

    const serverSort = {path: this.prop, direction: this.sortDir ? 'asc': 'desc' };
    this.serverSortChange.emit(serverSort);

    this.doSort();
  
    this.valuesChange.emit(this.values);
    this.currSortChange.emit(this.prop);

    this.setIcons();
    this.sort.emit(null);
  }

  public doSort() {
    this.values?.sort((a, b) => {
      const aVal = this.getVal(a, this.prop);
      const bVal = this.getVal(b, this.prop);

      // compare a>b, if a and b exist, or check for no a
      if ((aVal || typeof aVal === 'number') && (bVal || typeof bVal === 'number') && aVal > bVal || !aVal && typeof aVal !== 'number') {
        return this.sortDir ? 1 : -1;
      } else {
        return this.sortDir ? -1 : 1;
      }
    });
  }

  public getVal(item, props) {
    let i = clone(item); // in case it is an object that we don't want to change

    for (const k of props.split('.')) {
      if (i) {
        i = i[k];
      }
    }

    if (!i) {
      return i;
    }
    if (!this.matchCase && this.dataType === 'text') {
      i = lowerCase(i);
    } else if (this.dataType === 'number') {
      i = Number(i);
    } else if (this.dataType === 'date') {
      if ((typeof i) === 'string') {
        i = new Date(i);
      }
    }
    return i;
  }

}
