import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class CreateTagService extends Mutation {
  document = gql`
    mutation(
      $id: String!
      $client_id: String!
      $name: String!
      $description: String
      $color: String!
  ) {
    insert_Tag_one(
      object: {
        id: $id
        client_id: $client_id
        name: $name
        description: $description
        color: $color
      }
    ) {
        id
      }
  }`;
}
