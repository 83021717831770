// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalRightTop {
  line-height: 40px;
  font-size: 32px;
  color: var(--grey-lite);
  transition: 0.3s;
}

.modalRightTop:hover {
  cursor: pointer;
  color: rgb(60, 60, 60);
}

.modalLeft img {
  max-width: 90%;
  height: auto;
}

.modalCompany {
  width: auto;
  max-height: 60px;
}

.modalAvatar {
  box-sizing: border-box;
  border: 1px solid var(--grey-lite);
  max-height: 80px;
}

.modalLeftInfo {
  width: 100%;
}

.modalLeftInfo td {
  vertical-align: top;
  text-align: left;
  font-size: 0.8375em;
  line-height: 1em;
  color: rgb(90, 90, 90);
  padding-bottom: 0.75em;
}

.modalLeftInfo td:first-child {
  padding-right: 0.5em;
  font-weight: 700;
  color: rgb(60, 60, 60);
}

.modalLeftInfo tr:last-child td {
  padding-bottom: 0;
}

.modalLeftClientImg {
  height: auto;
  max-width: 100px;
}

.modalText {
  color: rgb(120, 120, 120);
  font-size: 1em;
  line-height: 1.5;
}

.h2 {
  font-size: 1.75em;
  line-height: 1.2;
  margin: -0.3em 0 0 0;
  color: rgb(60, 60, 60);
}

.modalAudio,
.modalVideo {
  position: relative;
  margin: 0 auto;
}

audio,
video {
  max-width: 90%;
  width: 90%;
}

.modalTranscript {
  width: 90%;
  font-style: italic;
  padding: 0 5%;
}

h6 {
  font-style: normal;
  font-weight: 700;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
