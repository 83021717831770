import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PhoneInputComponent } from './phone-input';

@NgModule({
  imports: [CommonModule,
    FormsModule,
  ],
  declarations: [PhoneInputComponent],
  exports: [PhoneInputComponent]
})
export class PhoneInputModule { }
