// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li.list-group-item {
  padding: 1px 10px;
  line-height: normal;
}

.btn-link {
  color: rgba(0, 0, 0, 0.85);
  text-decoration-style: unset;
}

/* Set a fixed scrollable wrapper */
.table-window {
  max-height: 70vh;
  width: 100%;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 4px;
}
.table-window.short {
  max-height: 40vh;
}

.avatarImage {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  border: solid 1px var(--grey-lite);
}

.table-date-input {
  width: 134px;
}

.fit-content {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.nowrap {
  white-space: nowrap;
}

#memberTable {
  position: relative;
}
#memberTable thead th {
  z-index: 1005;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
