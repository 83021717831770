import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

// used by Dynamic field data entry when a user 'deselects' a pick list option
//  from a PICKMANY type of dynamic field.  This mutation deletes the corresponding
//  data mapping in the db.

@Injectable({
  providedIn: 'root'
})
export class DeleteDynamicFieldValueService extends Mutation {
  document = gql`
    mutation (
      $id: String!
    ) {
      delete_DynamicFieldData_by_pk(id: $id){
        id
      }
    }
  `;
}
