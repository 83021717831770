// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterbox .input-group {
  color: var(--grey-3c3c3c);
}
.filterbox .form-control {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
  line-height: 40px;
  width: auto;
}
.filterbox .input-group-prepend > div {
  background-color: var(--white);
  border-radius: 20px 0 0 20px;
  border-width: 1px 0px 1px 1px;
  border-color: var(--border-grey);
  cursor: default;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  height: 100%;
}
.filterbox .input-group-append > div {
  background-color: var(--white);
  height: 100%;
  border-radius: 0 20px 20px 0;
  border-width: 1px 1px 1px 0;
  border-color: var(--border-grey);
  transition: var(--trans);
  color: var(--white);
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: default;
}
.filterbox .input-group-append > div.active {
  color: var(--grey-3c3c3c);
  cursor: pointer;
}
.filterbox .input-group-append > div.active:hover {
  color: var(--white);
  background-color: var(--primary);
}
.filterbox.new .form-control {
  height: 42px;
  line-height: 42px;
}
.filterbox.new .input-group-prepend > div {
  border-radius: 7px 0 0 7px;
  border-color: var(--grey-dcdcdc);
}
.filterbox.new .input-group-append > div {
  border-radius: 0 7px 7px 0;
  border-color: var(--grey-dcdcdc);
}
.filterbox .btn__new-search.active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.filterbox .searchBox {
  width: 0;
  overflow: hidden;
  transition: var(--trans3);
}
.filterbox .searchBox .form-control {
  height: 34px;
  line-height: 34px;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}
.filterbox .searchBox .form-control::placeholder {
  color: var(--grey-dcdcdc);
}
.filterbox .searchBox .form-control:focus {
  outline: none;
  border-color: var(--grey-787878);
}
.filterbox .searchBox .form-control:focus + .input-group-append div {
  border-color: var(--grey-787878);
}
.filterbox .searchBox .input-group-append {
  height: 34px;
  width: 34px;
  border-radius: 0 6px 6px 0;
}
.filterbox .searchBox .input-group-append div {
  border-radius: 0 6px 6px 0;
  padding: 0;
  display: flex;
  justify-content: center;
  color: var(--grey-787878);
}
.filterbox .searchBox .input-group-append div:hover {
  color: var(--white);
  border-color: var(--primary);
  background-color: var(--primary);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
