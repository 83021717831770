import { Injectable } from '@angular/core';
import { Subscription } from 'apollo-angular';

import gql from 'graphql-tag';
import { DynamicFieldEntity } from '../dynamic-field.model';


// exported for cache reference; must be unique across s5
export const LIST_DYNAMIC_FIELD_ENTITY = "ListDynamicEntity";

export interface DynamicFieldListEntity {
  DynamicFieldEntity: [DynamicFieldEntity]
}


@Injectable({
  providedIn: 'root'
})
export class ListDynamicFieldEntityNamesService extends Subscription<DynamicFieldListEntity>{

  document = gql`
    subscription ${LIST_DYNAMIC_FIELD_ENTITY} {
      DynamicFieldEntity {
        entityName: entity_name
      }
    }
  `;

}
