// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {
  color: var(--grey-lite);
}

.dropdown-container .input-group {
  position: relative;
  height: 38px;
  border-radius: 0.375rem;
  border: 1px solid #ccc;
}
.dropdown-container .input-group input {
  outline: none;
  padding-left: 10px;
}
.dropdown-container .input-group input::placeholder {
  color: var(--grey-mdlt) !important;
}

#panel {
  position: absolute;
  width: 100%;
  z-index: 1;
  font-size: var(--font-size-16);
}
#panel table {
  width: 90%;
}
#panel table tr {
  padding-left: 10px !important;
}
#panel table tr td {
  font-size: var(--font-size-16);
  color: #333;
}

.table-window {
  max-height: 20vh;
  overflow-y: scroll;
  margin-top: 0;
}
.table-window table tbody tr {
  border: none;
}
.table-window table tbody tr td {
  border: none;
}

.toggle-arrow {
  width: 13px;
  color: #999;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  display: inline-block;
  font-size: 14px;
}

.clear-wrapper {
  cursor: pointer;
  position: relative;
  width: 22px;
  color: #999;
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  text-align: start;
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
  line-height: 18px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
