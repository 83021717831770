import { Component, Input, OnInit } from '@angular/core';
import { Customer } from 'app/+client-admin/members/member.model';
import { Utils } from 'app/shared/utils/Utils';
import moment from 'moment';

@Component({
  selector: 's5-request-member-card',
  templateUrl: './request-member-card.component.html',
  styleUrls: ['./request-member-card.component.scss']
})
export class RequestMemberCardComponent implements OnInit {

  @Input() public set customer(c: Customer) {
    this._customer = c;
    this.initCard(c);

  }
  public get customer() {
    return this._customer;
  }
  public _customer: Customer;

  toggleTimeline = true;
  toggleWilling = true;
  currSort = 'name';

  constructor() { }

  respondedCount = 0;

  ngOnInit(): void {
    if (this.customer && this.customer.sentPrompts) {
      this.respondedCount = this.customer.sentPrompts.reduce((p, sp) => {
        if (sp.stories) {
          p += sp.stories.length;
        }
        return p;      
      }, 0);
    }
  }

  initCard(customer) {

  }

  customerAvatar(customer: Customer): string {
    return Utils.customerAvatar(customer);
  }

  calculateEngagementScore(customer) {
    if (!customer.activityLogs) {customer.activityLogs = [];}
    return customer.activityLogs.reduce((total, actLog) => {
      return total + actLog.activityType.points;
    }, 0);
  }

  respondedPerc() {
    const count = this.respondedCount;
    const length = this.customer.sentPrompts.length;
    let perc = 0;
    if (count > 0 && length > 0) {
      perc = Math.round((count / length) * 100);
    }
    return perc + '%';
  }

  respondedDates(customer) {

    const you = customer.activityLogs;

    if (!you.length) {
      return 'n/a';
    }

    const sortedActivities = you.sort((a, b) => {
      a = a ? new Date(a.dateTime) : '';
      b = b ? new Date(b.dateTime) : '';
      return b - a;
    });

    const now = moment(new Date().getTime());
    const then = moment(new Date(sortedActivities[0].dateTime).getTime());
    const diff = now.diff(then, 'days');

    return diff + ' days';

  }

}
