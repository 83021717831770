import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class AddStrategicInitiativeCampaignService extends Mutation {

  document = gql`
    mutation(
      $campaignId: String!
      $strategicInitiativeId: String!
    ) {
      insert_StrategicInitiative_Campaign_one(
        object: {
          campaign_id: $campaignId,
          strategic_initiative_id: $strategicInitiativeId
        }
      ) {
          campaign_id
          strategic_initiative_id
        }
    }`;

  }
