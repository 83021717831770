import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class RemoveStrategicInitiativeActivityGqlService extends Mutation {


  document = gql`
    mutation(
      $activityId: String!
      $strategicInitiativeId: String!
    ) {
      delete_StrategicInitiative_Activity_by_pk(
          activity_id: $activityId
          strategic_initiative_id: $strategicInitiativeId
      ) {
          activity_id
          strategic_initiative_id
        }
    }`;


}
