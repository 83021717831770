import { Component, Input } from '@angular/core';
import { StrategicInitiativeModel } from '../strategic-initiative.model';

import chroma from 'chroma-js';

@Component({
  selector: 's5-strategic-initiative-tag',
  templateUrl: './strategic-initiative-tag.component.html',
  styleUrls: ['./strategic-initiative-tag.component.scss']
})
export class StrategicInitiativeTagComponent {

  @Input() strategicInitiative: StrategicInitiativeModel


  chroma = chroma;


}
