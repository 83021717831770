import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrayService {

  public command: BehaviorSubject<any> = new BehaviorSubject(null);
  public tray;
  public trays: any = {};

  constructor() { }
  
  public clearAll() {
    this.command.next({clearAll: true});
  }

  public getTray(id: string) {
    return this.trays[id];
  }

  public register(tray: any) {
    this.trays[tray.id]=tray;
  }

  public requestTray(trayId: string, objects?: any) {
    this.command.next({requestTray: trayId, objects});
    this.trays[trayId] = {id: trayId, data: {...objects}};
    return this.trays[trayId];
  }

  public toggleTray(tray: any) {
    if (!tray?.id) { return; }
    this.tray = tray;
    this.trays[tray.id] = tray;
    this.command.next({toggleTray: tray});
  }

  public toggleInnerTray(tray: any) {
    if (!tray?.id) { return; }
    this.tray = tray;
    this.trays[tray.id] = tray;
    this.command.next({toggleInnerTray: tray});
  }

  public closeTray() {
    this.command.next({closeTray: true, tray: this.tray});
  }

  public closeInnerTray(tray: any) {
    this.command.next({closeInnerTray: tray});
  }

  public triggerFilter() {
    this.command.next({triggerFilter: true});
  }

  public sendMessage(msg: any) {
    this.command.next({msg: msg});
  }
}

