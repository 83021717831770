import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { ActivityType } from '../activity-type.model';


export interface ActivityTypePickerQueryResults {
  ActivityType: ActivityType[]
}


@Injectable({
  providedIn: 'root'
})
export class ActivityTypePickerGqlService extends Query<ActivityTypePickerQueryResults>{

  document = gql`
    query {
      ActivityType {
        id
        name
        points
        includeOnRequestForm
        createdAt
        updatedAt
      }
    }`

}
