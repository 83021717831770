import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import { User } from '../user.model';
import gql from 'graphql-tag';

export const LOAD_USER_QUERY_NAME = 'LoadUserQuery';

export interface LoadUserQueryResult {
  current_user: [User];
}

@Injectable({
  providedIn: 'root'
})
export class LoadUserGqlService extends Query<LoadUserQueryResult> {

  document = gql`
  query ${LOAD_USER_QUERY_NAME} {

    current_user {
      id
      name
      email
      superadmin
      auth0UserId
      clientRoles {
        id
        auth0UserId
        ClientRole {
          id
          role
        }
      }
      currentRole {
        id
        role
        client {
          id
          clientRoles {
            id
            role
          }
          companyPermissions {
            id
            name
          }
        }
        permission_members
        permission_companies
        permission_boards
        permission_stories
        permission_assets
        permission_prompts
        permission_requests
        permission_activities
        permission_campaigns
        permission_strategic_initiatives
      }
    }
  }
  `;
}