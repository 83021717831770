import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFieldsetConfigureComponent } from './dynamic-fieldset-configure/dynamic-fieldset-configure.component';
import { DynamicFieldComponent } from './dynamic-field/dynamic-field.component';
import { DynamicFieldEditorComponent } from './dynamic-field-editor/dynamic-field-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFieldsetComponent } from './dynamic-fieldset/dynamic-fieldset.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { MemberPickerModule } from '../members/member-picker/member-picker.module';
import { ActivityTypePickerModule } from '../activities/activity-type-picker/activity-type-picker.module';
import { MemberOrCompanyPickerModule } from '../members/member-or-company-picker/member-or-company-picker.module';
import { UserPickerModule } from '../users/user-picker/user-picker.module';
import { CompanyPickerModule } from '../companies/company-picker/company-picker.module';

@NgModule({
  declarations: [
    DynamicFieldComponent,
    DynamicFieldsetConfigureComponent,
    DynamicFieldEditorComponent,
    DynamicFieldsetComponent
  ],
  exports: [
    DynamicFieldComponent,
    DynamicFieldEditorComponent,
    DynamicFieldsetConfigureComponent,
    DynamicFieldsetComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    NgSelectModule,
    MemberPickerModule,
    CompanyPickerModule,
    ActivityTypePickerModule,
    MemberOrCompanyPickerModule,
    UserPickerModule
  ]
})
export class DynamicFieldsModule { }
