import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';


@Injectable({
  providedIn: 'root'
})
export class RemoveStrategicInitiativeRequestGqlService extends Mutation {


  document = gql`
    mutation(
      $requestId: String!
      $strategicInitiativeId: String!
    ) {
      delete_StrategicInitiative_Request_by_pk(
          request_id: $requestId
          strategic_initiative_id: $strategicInitiativeId
      ) {
          request_id
          strategic_initiative_id
        }
    }`;

}
