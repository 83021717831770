import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 's5-percent-change-chip',
  templateUrl: './percent-change-chip.component.html',
  styleUrls: ['./percent-change-chip.component.scss']
})
export class PercentChangeChipComponent implements OnInit {

  @Input() startCount: number;
  @Input() endCount: number;
  @Input() legend: string;
  @Input() isPercent: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
