import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderTitleService {

  public title = new BehaviorSubject<string>('');

  constructor() { }

  changeTitle(nomen: string) {
    this.title.next(nomen);
  }

  goBack() {
    history.back();
  }
}
