import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/auth/auth-guard';
import { LogoutComponent } from './logout/logout.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'client/home',
    pathMatch: 'full'
  },
  {
    path: 'session-timeout',
    loadChildren: () => import('./+session-timeout-page/session-timeout-page.module').then(m => m.SessionTimeoutPageModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./+client-admin/client-admin.module').then(m => m.ClientAdminModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./+global-admin/global-admin.module').then(m => m.GlobalAdminModule)
  },
  {
    path: 's/:id',
    loadChildren: () => import('./+story-viewer/single-story.module').then(m => m.SingleStoryModule)
  },
  {
    path: 's/:id/:slug',
    loadChildren: () => import('./+story-viewer/single-story.module').then(m => m.SingleStoryModule)
  },
  {
    path: 'mobile',
    loadChildren: () => import('./+mobile-prompt/mobile-prompt.module').then(m => m.MobilePromptModule)
  },
  {
    // also provide short URL aliases for mobile prompts:
    path: 'r/:id',
    redirectTo: 'mobile/respond-prompt/:id',
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
