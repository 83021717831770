import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class StrategicInitiativeUpdateService extends Mutation {

  document = gql`
    mutation(
      $id: String!
      $name: String!
      $color: String!
      $description: String
      $executiveSponsor: String
      $goal: String
      $influence: String
      $startDt: timestamptz
      $endDt: timestamptz
      $ownerId: String
    ) {
      update_StrategicInitiative_by_pk(
        pk_columns: {id: $id},
        _set: {
          id: $id
          name: $name
          color: $color
          description: $description
          executiveSponsor: $executiveSponsor
          goal: $goal
          influence: $influence
          startDt: $startDt
          endDt: $endDt
          owner_id: $ownerId
        }
      ) {
        id
      }
    }
  `;
}
