import { Pipe, PipeTransform } from '@angular/core';

// this pipe allows a callback to be specified to filter
//  elements in an ng-for.  Inspired by:
// https://stackoverflow.com/questions/34164413/how-to-apply-filters-to-ngfor

@Pipe({
  name: 'callback',
  pure: false
})
export class CallbackPipe implements PipeTransform {

  transform(items: any[], callback: (item: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item));
  }

}
