import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { map, Observable } from 'rxjs';
import { ListStrategicInitiativedGqlService } from '../gql/list-strategic-initiatives-gql.service';
import { StrategicInitiativeModel } from '../strategic-initiative.model';

import { AddStrategicInitiativeActivityGqlService } from './gql/add-strategic-initiative-activity-gql.service';
import { RemoveStrategicInitiativeActivityGqlService } from './gql/remove-strategic-initiative-activity-gql.service';
import { AddStrategicInitiativeAssetGqlService } from './gql/add-strategic-initiative-asset-gql.service';
import { RemoveStrategicInitiativeAssetGqlService } from './gql/remove-strategic-initiative-asset-gql.service';
import { AddStrategicInitiativeBoardGqlService } from './gql/add-strategic-initiative-board-gql.service';
import { RemoveStrategicInitiativeBoardGqlService } from './gql/remove-strategic-initiative-board-gql.service';
import { AddStrategicInitiativePromptGqlService } from './gql/add-strategic-initiative-prompt-gql.service';
import { RemoveStrategicInitiativePromptGqlService } from './gql/remove-strategic-initiative-prompt-gql.service';
import { AddStrategicInitiativeRequestGqlService } from './gql/add-strategic-initiative-request-gql.service';
import { RemoveStrategicInitiativeRequestGqlService } from './gql/remove-strategic-initiative-request-gql.service';
import { AddStrategicInitiativeStoryGqlService } from './gql/add-strategic-initiative-story-gql.service';
import { RemoveStrategicInitiativeStoryGqlService } from './gql/remove-strategic-initiative-story-gql.service';
import { AddStrategicInitiativeCampaignService } from './gql/add-strategic-initiative-campaign.service';
import { RemoveStrategicInitiativeCampaignService } from './gql/remove-strategic-initiative-campaign.service';
import { cloneDeep } from 'lodash';
import { ChildManager } from '../../../shared/child-manager/child-manager';
import { EDIT_CAMPAIGN_QUERY_NAME } from '../../../+client-admin/campaigns/gql/edit-campaign-gql.service';

// This is written here and not in a convenience service
//  so we can dyanimcally edit this GQL to tag different
//  entities with the same Mutation


@Component({
  selector: 's5-sa-tagger',
  templateUrl: './strategic-initiative-tagger.component.html',
  styleUrls: ['./strategic-initiative-tagger.component.scss']
})
export class StrategicInitiativeTaggerComponent extends ChildManager implements OnInit {

  @Input() entityType: string = "asset";
  @Input() entityId: string;

  @Input() set tags(tags) {
    // a mapping/flattening is required here due to the data shape
    this.currentChildren = tags ? tags.map(t => { return t.strategicInitiative }) : [];
    this.originalChildren = cloneDeep(this.currentChildren);
  }
  @Output() tagsChange: EventEmitter<StrategicInitiativeModel[]> = new EventEmitter<StrategicInitiativeModel[]>();

  allTags$: Observable<StrategicInitiativeModel[]>;


  constructor(
    private listStrategicInitiativesGqlService: ListStrategicInitiativedGqlService,

    private addStrategicInitiativeActivityGqlService: AddStrategicInitiativeActivityGqlService,
    private removeStrategicInitiativeActivityGqlService: RemoveStrategicInitiativeActivityGqlService,
    private addStrategicInitiativeAssetGqlService: AddStrategicInitiativeAssetGqlService,
    private removeStrategicInitiativeAssetGqlService: RemoveStrategicInitiativeAssetGqlService,
    private addStrategicInitiativeBoardGqlService: AddStrategicInitiativeBoardGqlService,
    private removeStrategicInitiativeBoardGqlService: RemoveStrategicInitiativeBoardGqlService,
    private addStrategicInitiativePromptGqlService: AddStrategicInitiativePromptGqlService,
    private removeStrategicInitiativePromptGqlService: RemoveStrategicInitiativePromptGqlService,
    private addStrategicInitiativeRequestGqlService: AddStrategicInitiativeRequestGqlService,
    private removeStrategicInitiativeRequestGqlService: RemoveStrategicInitiativeRequestGqlService,
    private addStrategicInitiativeStoryGqlService: AddStrategicInitiativeStoryGqlService,
    private removeStrategicInitiativeStoryGqlService: RemoveStrategicInitiativeStoryGqlService,

    private addStrategicInitiativeCampaignService: AddStrategicInitiativeCampaignService,
    private removeStrategicInitiativeCampaignService: RemoveStrategicInitiativeCampaignService,

  ) {
    super();
  }

  ngOnInit(): void {
    this.allTags$ = this.listStrategicInitiativesGqlService.subscribe()
      .pipe(map(({ data }) => {
        return data.strategicInitiatives;
      }));
  }

  // called by the edit screen storing strategic initiatives
  processAdds(childrenToAdd, parentId: string): void {
    childrenToAdd.forEach(async (sa) => {
      switch (this.entityType) {
        case 'activity':
          await this.addStrategicInitiativeActivityGqlService.mutate({ activityId: parentId, strategicInitiativeId: sa.id }).toPromise();
          break;
        case 'asset':
          await this.addStrategicInitiativeAssetGqlService.mutate({ assetId: parentId, strategicInitiativeId: sa.id }).toPromise()
          break;
        case 'board':
          await this.addStrategicInitiativeBoardGqlService.mutate({ boardId: parentId, strategicInitiativeId: sa.id }).toPromise();
          break;
        case 'campaign':
          await this.addStrategicInitiativeCampaignService.mutate({ campaignId: parentId, strategicInitiativeId: sa.id }, { refetchQueries: [EDIT_CAMPAIGN_QUERY_NAME]}).toPromise();
          break;
        case 'prompt':
          await this.addStrategicInitiativePromptGqlService.mutate({ promptId: parentId, strategicInitiativeId: sa.id }).toPromise();
          break;
        case 'request':
          await this.addStrategicInitiativeRequestGqlService.mutate({ requestId: parentId, strategicInitiativeId: sa.id }).toPromise();
          break;
        case 'story':
          await this.addStrategicInitiativeStoryGqlService.mutate({ storyId: parentId, strategicInitiativeId: sa.id }).toPromise();
          break;
        default:
          console.error(`Enity ${this.entityType} not found - can't add strategic initiative.`)
      }
    });
  }


  processRemoves(childrenToRemove, parentId: string): void {
    childrenToRemove.forEach(async (sa) => {
      switch (this.entityType) {
        case 'activity':
          await this.removeStrategicInitiativeActivityGqlService.mutate({ activityId: parentId, strategicInitiativeId: sa.id }).toPromise();
          break;
        case 'asset':
          await this.removeStrategicInitiativeAssetGqlService.mutate({ assetId: parentId, strategicInitiativeId: sa.id }).toPromise()
          break;
        case 'board':
          await this.removeStrategicInitiativeBoardGqlService.mutate({ boardId: parentId, strategicInitiativeId: sa.id }).toPromise();
          break;
        case 'campaign':
          await this.removeStrategicInitiativeCampaignService.mutate({ campaignId: parentId, strategicInitiativeId: sa.id }, { refetchQueries: [EDIT_CAMPAIGN_QUERY_NAME]}).toPromise();
          break;
        case 'prompt':
          await this.removeStrategicInitiativePromptGqlService.mutate({ promptId: parentId, strategicInitiativeId: sa.id }).toPromise();
          break;
        case 'request':
          await this.removeStrategicInitiativeRequestGqlService.mutate({ requestId: parentId, strategicInitiativeId: sa.id }).toPromise();
          break;
        case 'story':
          await this.removeStrategicInitiativeStoryGqlService.mutate({ storyId: parentId, strategicInitiativeId: sa.id }).toPromise();
          break;
        default:
          console.error(`Enity ${this.entityType} not found - can't add strategic initiative.`)
      }
    });
  }

  compareTags(a: any, b: any) {
    return a.id === b.id;
  }


}
