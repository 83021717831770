import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnInit, OnChanges } from '@angular/core';
import { parsePhoneNumberFromString, isValidNumber, CountryCode, NationalNumber } from 'libphonenumber-js';



@Component({
  selector: 'phone-input',
  templateUrl: './phone-input.html',
  styleUrls: ['./phone-input.scss']
})
export class PhoneInputComponent implements OnInit, OnChanges {

  @Input() name: string;
  @Input() disabled = false;
  @Input() useCheckbox = false;
  @Input() redOnEmpty = true;
  @Input() showNumbers = true;

  @Output() change: EventEmitter<null> = new EventEmitter<null>();

  @Input() checkboxValue: boolean;
  @Output() checkboxValueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() number: string;
  @Output() numberChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() countryCode: string;
  @Output() countryCodeChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() isValid: boolean;
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();


  countryCodes: Array<string> = ['001', 'AD', 'AE', 'AM', 'AO', 'AQ', 'AR', 'AU', 'BB',
    'BR', 'BS', 'BY', 'CA', 'CH', 'CL', 'CN', 'CS', 'CX', 'DE', 'ES', 'FR', 'GB', 'HK', 'HU', 'ID', 'IT', 'JP', 'KR',
    'MX', 'MY', 'NL', 'NZ', 'PE', 'PG', 'PH', 'PL', 'PT', 'RE', 'SE', 'SG', 'TH', 'TW', 'US', 'VN', 'YT', 'ZW', 'ZZ'];

  inputClasses: Array<string> = ['form-control'];
  divClasses: Array<string> = [];

  hash: string;
  thisID: string;

  constructor(private cdr: ChangeDetectorRef) {

  }

  ngOnInit() {
    setTimeout(() => {
      // Set a stupid little hash for an individual ID (since we can have multiple of these in a view)
      // so we have a label to grab onto for a checkbox.
      this.hash = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
      this.thisID = 'phCheck-' + this.hash;
      this.validatePhone();
    });
  }

  ngOnChanges() {
    setTimeout(() => { this.validatePhone(); });
  }

  validatePhone() {
    if (this.number && this.number.length >= 0 && this.number !== '+') {
      const number = parsePhoneNumberFromString(this.number, <CountryCode>this.countryCode);
      if (number) {
        this.number = number.format('INTERNATIONAL');
      }
    }
    this.numberChange.emit(this.number);
    if (this.number) {
      if (!this.countryCode) {
        this.countryCode = 'US';
        this.countryCodeChange.emit(this.countryCode);
      }
      this.isValid = isValidNumber(<NationalNumber>this.number, <CountryCode>this.countryCode);
    } else {
      this.isValid = false;
    }
    this.isValidChange.emit(this.isValid);
    if (this.isValid || ((!this.number || this.number.length === 0) && !this.redOnEmpty)) {
      this.inputClasses = ['form-control'];
      this.divClasses = [''];
    } else {
      this.inputClasses = ['form-control', 'form-control-danger'];
      this.divClasses = ['has-danger'];
    }
    this.change.emit();
  }

  countryCodeChanged() {
    this.countryCodeChange.emit(this.countryCode);
    this.validatePhone();
  }


}
