import { Component, Directive, DoCheck, ElementRef, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import _ from 'lodash';
import { Khronos } from 'ngx-bootstrap/chronos/testing/chain';
import { TrayService } from '../trays/tray.service';
import { Utils } from '../utils/Utils';

@Component({
  selector: 's5-table-tray-button',
  template: `<i class="far fa-arrow-to-left fa-lg mx-0 px-0" [ngStyle]="{position: absolute}" (click)="trayService.toggleTray(tray)">`,
})
export class TableTrayButtonComponent implements OnInit {
  constructor(protected trayService: TrayService) {};

  @Input() tray = {};

  ngOnInit() {}
}

export interface TableControlsColumn {
  view?: boolean;
  th?: JQuery<HTMLElement>;
  tds?: JQuery<HTMLElement>;
}

@Directive({
  selector: '[s5TableControls]'
})
export class TableControlsDirective implements DoCheck {

  private _tableControlsConfig;
  get s5TableControls() {
    return this._tableControlsConfig;
  }
  @Input() set s5TableControls(tableControlsConfig: any) {
    this.rowIds = $(`#${this.id} tbody tr`);
    this._tableControlsConfig = tableControlsConfig;
    this.init();
  };

  tray: any = {
    id: '',
    trayRef: 'table',
    trayTitle: '',
    trayTitleTooltip: '',
    innerTrayTitle: '',
    innerTrayProfileTitle: '',
    trayWidth: 500,
    trayWidthInner: 500,
    data: {}
  }

  id: string;
  headers: ElementRef[];
  rows: ElementRef[];
  rowIds: any = [];
  views;

  columns: TableControlsColumn[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private trayService: TrayService
  ) { 
    const tableTrayButtonRef = this.viewContainer.createComponent(TableTrayButtonComponent); 
    tableTrayButtonRef.instance.tray = this.tray;
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  ngDoCheck(){
    const newRowIds = $(`#${this.id} tbody tr`);
    const diff = _.difference(newRowIds, this.rowIds);
    if (diff.length !== 0) {
      this.rowIds = newRowIds;
      this.buildColumns();
    }
  }

  init() {
    this.id = this.getId();
    let views = Utils.getTableColumnViews(this.id);

    if (!views) {
      views = Array(this.getHeaders().length).fill(true);
    } 
    views.map((view: boolean) => this.columns.push({view}));
    
    setTimeout(() => {
      this.buildColumns();
    }, 100);
    
  }

  initTray() {
    this.tray.trayTitle = this.s5TableControls.label;
    this.tray.data.checkId = this.id;
    this.tray.data.columns = this.columns;
  }

  getId(): string {
    return this.templateRef.elementRef.nativeElement.previousSibling.id;
  }

  getHeaders(): HTMLElement[] {
    return Object.values($(`#${this.id} thead tr th`)).filter((item) => item.nodeName === 'TH');
  }

  getTH(index: number): JQuery<HTMLElement> {
    return $(`#${this.id} thead tr th:nth-of-type(${index + 1})`);
  }

  getColumnTDs(index: number): JQuery<HTMLElement> {
    return $(`#${this.id} tbody tr td:nth-of-type(${index + 1})`);
  }

  buildColumns() {
    this.columns.map((column: TableControlsColumn, index: number) => {
      column.tds = this.getColumnTDs(index);
      column.th = this.getTH(index);
      
      if (column.view === true) {
        column.th.show();
        column.tds.show();
      } else {
        column.th.hide();
        column.tds.hide();
      }
    });

    this.initTray();
  }

}
