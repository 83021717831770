// adapted from https://coryrylan.com/blog/introduction-to-angular-2-pipes

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  public transform(val, len) {
    if (!val) {
      return val;
    }

    if (val.length > Math.abs(len)) {
      if (len > 0) {
        return val.substring(0, len - 3) + '...';
      } else {
        return '...' + val.substring(val.length + len);
      }
    } else {
      return val;
    }
  }
}
