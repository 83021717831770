import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'singleSelectFilter' })
export class SingleSelectFilterPipe implements PipeTransform {
  public transform(suggestedValues: string[], currentValue: string): string[] {
    if (!currentValue) {
      return suggestedValues;
    }
    const currentSearch = currentValue.trim();
    const ret: string[] = [];
    for (const suggestedValue of suggestedValues) {
      if (!currentSearch || currentSearch.length === 0 ||
        suggestedValue.toString().toLowerCase().indexOf(currentSearch.toLowerCase()) > -1) {
        ret.push(suggestedValue);
      }
    }
    return ret;
  }
}
