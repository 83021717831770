import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'advanced-filter-field',
  templateUrl: './advanced-filter-field.component.html',
  styleUrls: ['./advanced-filter-field.component.scss']
})
export class AdvancedFilterFieldComponent implements OnInit {

  @Input() public value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() public label: string;
  @Input() public otherValues: Array<any>;
  @Input() public otherValuesKey: string;
  @Input() public otherValuesAreCommaSeparated = false;
  @Input() public additionalClasses = [];
  @Input() public suppressSuggestions = false;

  constructor() { }

  ngOnInit() {
  }

  valChanged() {
    this.valueChange.emit(this.value);
  }

}
