import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityTypePickerComponent } from './activity-type-picker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
// import { CustomerCardComponent } from '../member-card/member-card.component';


@NgModule({
  declarations: [
    ActivityTypePickerComponent,
    // CustomerCardComponent
  ],
  exports: [
    ActivityTypePickerComponent,
    // CustomerCardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ]
})
export class ActivityTypePickerModule { }
