import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPickerComponent } from './user-picker.component';
import { UserTagComponent } from './user-tag/user-tag.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { InfiniteSelectModule } from 'app/shared/infinite-select/infinite-select.module';



@NgModule({
  declarations: [
    UserPickerComponent,
    UserTagComponent
  ],
  exports: [
    UserPickerComponent,
    UserTagComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    NgSelectModule,
    InfiniteSelectModule
  ]
})
export class UserPickerModule { }
