import { Injectable } from '@angular/core';
import { Subscription } from 'apollo-angular';
import gql from 'graphql-tag';
import { DynamicFieldType } from '../dynamic-field.model';

// exported for cache reference; must be unique across s5
export const LIST_DYNAMIC_FIELD_TYPES = "ListDynamicFieldType";

export interface DynamicFieldListResults {
  DynamicFieldType: [DynamicFieldType]
}

@Injectable({
  providedIn: 'root'
})
export class ListDynamicFieldTypesService extends Subscription<DynamicFieldListResults> {

  document = gql`
    subscription  {
      DynamicFieldType {
        type
        description
      }
    }
  `;
}
