import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Subscription } from 'apollo-angular';
import { Tag } from '../tag.model';


export const LIST_TAGS_QUERY_NAME = 'ListTagsQuery';


export interface ListTagsSubscriptionResult {
  Tag: [Tag]
}

@Injectable({
  providedIn: 'root'
})
export class ListTagsGqlService extends Subscription<ListTagsSubscriptionResult>{

  document = gql`
    subscription ${LIST_TAGS_QUERY_NAME} {
      Tag(order_by: { name: asc }) {
        id
        name
        color
        description
        client_id
        medias_aggregate {
          aggregate {
            count
          }
        }
      }
    }

  `

}
