import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberPickerComponent } from './member-picker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CustomerCardComponent } from '../member-card/member-card.component';


@NgModule({
  declarations: [
    MemberPickerComponent,
    CustomerCardComponent
  ],
  exports: [
    MemberPickerComponent,
    CustomerCardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ]
})
export class MemberPickerModule { }
