import { differenceBy } from 'lodash';

// this is not a component but an abstract class that
//  all components that manage children should extend
//  for connvenience.  It calculates adds and removes
//  of child objects
//
//  this was created to refactor:
//  1. 'delete all and then reinsert all child relations'
//     anti-pattern
//  2. 'parents managing children records' antipattern


export abstract class ChildManager {
  currentChildren;
  originalChildren;

  save(parentId:string) {
    this.processRemoves(this.getRemoves(), parentId)
    this.processAdds(this.getAdds(), parentId)
  }

  abstract processAdds(s5Model, parentId:string);
  abstract processRemoves(s5Model, parentId:string);

  getAdds() {
    return differenceBy( this.currentChildren, this.originalChildren, 'id');
  }

  getRemoves() {
    return differenceBy( this.originalChildren, this.currentChildren, 'id');
  }

}
