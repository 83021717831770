import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ListCompaniesPickerGqlService } from '../companies-gql/list-companies-picker-gql.service';
import { Company } from '../company.model';

@Component({
  selector: 's5-company-picker',
  templateUrl: './company-picker.component.html',
  styleUrls: ['./company-picker.component.scss']
})
export class CompanyPickerComponent {

  @Input() company: Company;
  @Output() companyChange: EventEmitter<Company> = new EventEmitter<Company>();

  isLoading = false;
  companies: Company[] = [];

  companiesBuffer = [];
  bufferSize = 50;
  total = 0;
  numberOfItemsFromEndBeforeFetchingMore = 10;
  searchVal = '';

  constructor(
    private listCompaniesPickerGqlService: ListCompaniesPickerGqlService
  ) {
  }

  ngOnInit() {
    if (!this.company?.id) {
      delete this.company;
    }
    this.fetchMore()
  }

  onScrollToEnd() {
    this.fetchMore();
  }

  onScroll({ end }) {
    if (this.isLoading || this.companies.length <= this.companiesBuffer.length) {
      return;
    }

    if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.companiesBuffer.length) {
      this.fetchMore();
    }
  }

  onChange(event) {
    this.companyChange.emit(event);
    this.searchVal = '';
  }

  private fetchMore() {
    const len = this.companiesBuffer.length;
    this.isLoading = true;

    const vars = {
      offset: len,
      limit: this.bufferSize,
      search: `%${this.searchVal}%`,
    };
    this.listCompaniesPickerGqlService.watch(vars).valueChanges.subscribe(({ data }) => {
      this.companies = data.companies;
      this.total = data.total.aggregate.count;
      this.companiesBuffer = this.companiesBuffer.concat(this.companies);
      this.isLoading = false;
    })
  }

  onSearch(searchVal) {
    this.companies = [];
    this.companiesBuffer = [];
    this.searchVal = searchVal.term;
    this.fetchMore();
  }

  onClear() {
    this.searchVal = '';
    this.companies = [];
    this.companiesBuffer = [];
    this.fetchMore();
  }

}
