export const environment = {
  production: true,
  environmentName: 'stage',
  hmr: false,
  baseHost: 'stageslapfive.com',
  cookieHost: 'stageslapfive.com',
  apiUrl: 'https://app.stageslapfive.com',
  auth0CallbackUrl: 'https://app.stageslapfive.com',
  auth0ClientID: 'Fmpvsw7HpeCwS41fuAojU_52f3oHdTU6',
  auth0CustomDomain: 'auth.stageslapfive.com',
  auth0Tenant: 'slapfive-stage',
  auth0LogoutUrl: `https://www.slapfive.com`,
  auth0SessionTimeoutUrl: `https://app.stageslapfive.com/session-timeout`,
  auth0SessionWarningSeconds: 3600,
  auth0SessionTimeoutSeconds: 120,
  auth0LoginRedirUrl: '/client/home',
  gqlUrl: 'https://gql.stageslapfive.com/v1/graphql',
  gqlWsUrl: 'wss://gql.stageslapfive.com/v1/graphql',
  subdomain_redir: false,
  log_level: 'debug',
  google_storage_baseUrl: 'https://www.googleapis.com/upload/storage/v1/b/slapfive/o?uploadType=media',
  google_storage_folders: {
    audio: 'a',
    video: 'v',
    image: 'i'
  },
  auth0Audience: 'stageslapfive.com/api',
  sentryDsn: 'https://d27e8c7bb795426ab017ac1c27bcb499@o211966.ingest.sentry.io/1338037',
  gcsServingUrl: 'https://media.slapfive.com',
};
