import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Subscription } from 'apollo-angular';
import { StrategicInitiativeModel } from '../strategic-initiative.model';



export const LIST_STRATEGIC_INITITATIVES_QUERY_NAME = 'ListStrategicInitiativesQuery';

export interface ListStrategicInitiativesGqlResult {
  strategicInitiatives: [StrategicInitiativeModel];
}

@Injectable({
  providedIn: 'root'
})
export class ListStrategicInitiativedGqlService extends Subscription<ListStrategicInitiativesGqlResult> {

  document = gql`
  subscription ${LIST_STRATEGIC_INITITATIVES_QUERY_NAME}  {
    strategicInitiatives: StrategicInitiative(order_by: { name: asc }) {
      id
      name
      color
      description
      executiveSponsor
      owner {
        id
        name
        avatarImage {
          id
          url
        }
      }
    }
  }
`;

}
