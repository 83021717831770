import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class AddStrategicInitiativeActivityGqlService extends Mutation {

  document = gql`
    mutation(
      $activityId: String!
      $strategicInitiativeId: String!
    ) {
      insert_StrategicInitiative_Activity_one(
        object: {
          activity_id: $activityId,
          strategic_initiative_id: $strategicInitiativeId
        }
      ) {
          activity_id
          strategic_initiative_id
        }
    }`;
}
