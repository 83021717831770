import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, Observable } from 'rxjs';

import { DynamicFieldEntity, DynamicFieldModel, DynamicFieldType, DynamicFieldPickListValue } from '../dynamic-field.model';
import { ListDynamicFieldTypesService } from '../dynamic-fields-gql/list-dynamic-field-types.service';
import { DeleteDynamicFieldService } from '../dynamic-fields-gql/delete-dynamic-field.service';
import { CreateDynamicFieldService } from '../dynamic-fields-gql/create-dynamic-field.service';
import { UpdateDynamicFieldService } from '../dynamic-fields-gql/update-dynamic-field.service';
import { ListDynamicFieldEntityNamesService } from '../dynamic-fields-gql/list-dynamic-field-entity-names.service';
import { CreateDynamicFieldPickValueService } from '../dynamic-fields-gql/create-dynamic-field-pick-value.service';
import { UpdateDynamicFieldPickValueService } from '../dynamic-fields-gql/update-dynamic-field-pick-value.service';
import { DeleteDynamicFieldPickValueService } from '../dynamic-fields-gql/delete-dynamic-field-pick-value.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import cuid from 'cuid';
import { camelCase } from 'lodash';
import { ListDynamicFieldPickValuesService } from '../dynamic-fields-gql/list-dynamic-field-pick-values.service';
import { UserService } from 'app/+client-admin/users/user.service';
import { User } from 'app/+client-admin/users/user.model';



interface PickListEditorEntry {
  id: string
  name: string
  dynamicFieldId: string
  deleted: boolean
}


@Component({
  selector: 's5-dynamic-field-editor',
  templateUrl: './dynamic-field-editor.component.html',
  styleUrls: ['./dynamic-field-editor.component.scss']
})
export class DynamicFieldEditorComponent implements OnInit {

  df:DynamicFieldModel;
  user: User;
  pickListToSave = new Array<PickListEditorEntry>();

  @Input('dynamicField') set dynamicField(df: DynamicFieldModel) {
    this.df = df;
    this.sortValues();
    if (this.df) {
      this.dynamicFieldForm.setValue({
        label: this.dynamicField.label,
        type: this.dynamicField.type,
        description: this.dynamicField.description,
        required: this.dynamicField.required || false,
        dataName: this.dynamicField.dataName || '',
        entityName: this.dynamicField.entityName,
        flag: this.dynamicField.flag !== false
      });
    }
  };

  get dynamicField():DynamicFieldModel {
    return this.df;
  }

  @Output() dynamicFieldChange: EventEmitter<DynamicFieldModel> = new EventEmitter<DynamicFieldModel>();

  customFieldType$:Observable<DynamicFieldType[]>;
  customFieldEntity$:Observable<DynamicFieldEntity[]>;
  pickListValue$:Observable<DynamicFieldPickListValue[]>;

  dynamicFieldForm: FormGroup;

  constructor(
    private listDynamicFieldTypesService:ListDynamicFieldTypesService,
    private listDynamicFieldEntityNamesService:ListDynamicFieldEntityNamesService,
    private deleteDynamicFieldService:DeleteDynamicFieldService,
    private createDynamicFieldService:CreateDynamicFieldService,
    private updateDynamicFieldService:UpdateDynamicFieldService,
    private listDynamicFieldPickValuesService:ListDynamicFieldPickValuesService,
    private createDynamicFieldPickValueService:CreateDynamicFieldPickValueService,
    private updateDynamicFieldPickValueService:UpdateDynamicFieldPickValueService,
    private deleteDynamicFieldPickValueService:DeleteDynamicFieldPickValueService,
    private userService: UserService,

    public formBuilder: FormBuilder
    ) {
      this.dynamicFieldForm = this.formBuilder.group({
        label: ['', [Validators.required]],
        type: ['', [Validators.required]],
        description: [''],
        entityName: [''],
        dataName: [''],
        required: [''],
        flag: ['']
      });
    }

  async ngOnInit(): Promise<void> {
    this.user = await this.userService.getUser();

    this.customFieldType$ = this.listDynamicFieldTypesService.subscribe()
      .pipe(map(({ data }) => {
        return data.DynamicFieldType;
      }));

    this.customFieldEntity$ = this.listDynamicFieldEntityNamesService.subscribe()
      .pipe(map(({ data }) => {
        return data.DynamicFieldEntity;
      }));

  }

  // build a derived camelcase dataName from the label; this gets saved
  //  into the custom field and is used by the API to set values.
  buildDataName(event:KeyboardEvent) {
    this.dynamicFieldForm.patchValue({ dataName: camelCase(event.target['value']) })
  }

  public isPickList() {
    const type = this.dynamicFieldForm.get('type').value;
    return type === 'PICKONE' || type === 'PICKMANY';
  }

  async save() {

    const dynamicFieldToSave:DynamicFieldModel = {
      id: this.dynamicField.id,
      label: this.dynamicFieldForm.get('label').value,
      type: this.dynamicFieldForm.get('type').value,
      description: this.dynamicFieldForm.get('description').value,
      required: this.dynamicFieldForm.get('required').value || false,
      entityName: this.dynamicFieldForm.get('entityName').value,
      dataName: this.dynamicFieldForm.get('dataName').value,
      flag: this.dynamicFieldForm.get('flag').value
    };

    if (dynamicFieldToSave.id) {
      await this.updateDynamicFieldService.mutate(dynamicFieldToSave).toPromise()
    } else {

      dynamicFieldToSave.id = cuid();

      try {
        await this.createDynamicFieldService.mutate(dynamicFieldToSave).toPromise();
      } catch(err) {
        console.log("SEAN ERR", err);
        if (err.message?.includes('Uniqueness violation')) {
          alert("This Dynamic Field Label and Description already exists for the chosen Entity.");
        }
      }

    }

    // now create/update/delete pick list values as applicable:
    if (this.dynamicField.dynamicFieldPickListValues?.length > 0 ) {

      this.dynamicField.dynamicFieldPickListValues.forEach(plv => {
        if (plv.deleted) {
          // this plv was deleted
          delete plv.edited;
          delete plv.deleted;
          this.deleteDynamicFieldPickValueService.mutate({ id: plv.id }).subscribe();
        } else if (!plv.id) {
          // this plv is new
          delete plv.edited;
          delete plv.deleted;
          plv.id = cuid();
          plv.dynamicFieldId = dynamicFieldToSave.id;
          this.createDynamicFieldPickValueService.mutate(plv).subscribe();
        } else if (plv.edited) {
          // this plv was updated
          delete plv.edited;
          delete plv.deleted;
          delete plv['__typename'];
          this.updateDynamicFieldPickValueService.mutate(plv).subscribe();
        } else {
          console.log("This plv was untouched", plv);
        }
      });

    }

    // effectively close the editor.  Delay is for UX
    setTimeout(() => {
      this.close();
    }, 500);
  }

  close() {
    if (this.dynamicField.id) {
      this.dynamicField.inlineEdit = false;
    } else {
      // df was deleted
      this.dynamicField = undefined;
    }
    this.dynamicFieldChange.emit(this.dynamicField);
  }


  // pick list value maintenance functions

  public addPickListValue() {
    const name = prompt("Please enter the name of the new pick list value");
    if (!name || name == "") {
      return;
    }

    const newPlv = { name: name,  dynamicFieldId: this.dynamicField.id, deleted: false, order: (this.dynamicField.dynamicFieldPickListValues?.length || 0) * 2 };

    // initialize pick list if it doesn't exist
    if (!this.dynamicField.dynamicFieldPickListValues) {
      this.dynamicField.dynamicFieldPickListValues = [newPlv];
    } else {
      this.dynamicField.dynamicFieldPickListValues.push(newPlv);
    }
  }


  public updatePickListValue(plv:DynamicFieldPickListValue) {
    console.log("editing team " + plv.id);
    const newName = prompt("Please enter the new name of this pick list value", plv.name);
    if (newName && newName != "" && newName != plv.name) {
      plv.edited = true;
      plv.name = newName;
    }
  }

  public moveUpPickListValue(plv: DynamicFieldPickListValue) {
    plv.order = plv.order - 3;
    plv.edited = true;
    this.sortValues();
  }

  public moveDownPickListValue(plv: DynamicFieldPickListValue) {
    plv.order = plv.order + 3;
    plv.edited = true;
    this.sortValues();
  }

  public deletePickListValue(plv:DynamicFieldPickListValue) {
    if (confirm(`Are you sure you want to delete the term "${plv.name}"?`)) {
      // monkey patch in a deleted flag to be used during saving
      plv['deleted'] = true;
    }
  }

  private sortValues() {
    const values: Array<DynamicFieldPickListValue> = this.dynamicField?.dynamicFieldPickListValues;
    if (!values) {
      return;
    }
    values.sort((a, b) => {
      if (a.order > b.order || (a.order === b.order && a.name > b.name)) {
        return 1;
      } else {
        return -1;
      }
    });

    for (let i = 0; i < values.length; i++) {
      if (values[i].order != i * 2) {
        values[i].order = i * 2;
        values[i].edited = true;
      }
    }
  }
}
