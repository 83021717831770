import { Injectable } from '@angular/core';
import { Mutation } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class UpdateDynamicFieldPickValueService extends Mutation {

  document = gql`
    mutation (
      $id: String!
      $name: String!
      $order: Int!
    ) {
      update_DynamicFieldPickListValue_by_pk(
        pk_columns: {id: $id},
        _set: {
          name: $name,
          order: $order
        }
      ) {
        id
      }
    }
    `;

}
