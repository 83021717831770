import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subscription, Observable, fromEvent } from 'rxjs';


@Component({
  selector: 's5-tray',
  templateUrl: './tray.component.html',
  styleUrls: ['./tray.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrayComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('pageTray') tray: ElementRef;

  @Input() visible = false;
  @Input() innerVisible = false;
  @Input() trayTitle = '';
  @Input() innerTrayTitle = '';
  @Input() trayTitleTooltip;
  @Input() innerTrayTitleTooltip;
  @Input() trayWidth = 342;
  @Input() trayWidthInner = 342;
  @Input() closeBoth = true;
  @Output() innerToggle = new EventEmitter();
  @Output() innerTrayToggle = new EventEmitter();

  navbar;
  vh;
  zIndex = 1290;
  // window;

  scrollObservable$: Observable<Event>;
  scrollSubscription$: Subscription;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  navbarTop = 0;


  constructor(
    private elmRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      this.navbar = document.getElementById('navbarTop') as HTMLElement;
      // this.vh = this.document.defaultView;
      let setHeight = window.innerHeight + 'px';
      if (this.navbar) {
        
        // console.log(this.navbar.classList.value)
        if (this.navbar.classList.value.includes('fixed')) {
          setHeight = window.innerHeight - this.navbar.offsetHeight + 'px';
          this.navbarTop = this.navbar.offsetHeight;
        } else {
          setHeight = window.innerHeight + 'px';
        }
      }
 

      const parent = this.tray.nativeElement;

      const appPageTrayHolder = document.getElementById('appPageTray-holder') as HTMLElement;
      const appPageInnerTrayHolder = parent.querySelector('.appPageInnerTray-holder');

      let matches: any = [];
      matches = document.querySelectorAll('.traySetHeight');
      matches.forEach((i: any) => {
        this.renderer.setStyle(i, 'height', setHeight);
      });

      // this.renderer.setStyle(appPageTrayHolder, 'top', `${this.navbar.offsetHeight}px`)
      
      // ----- Use [style.x] in the markup instead
      // this.renderer.setStyle(appPageTrayHolder, 'width', `${this.trayWidth}px`)
      // this.renderer.setStyle(appPageTrayHolder, 'right', `-${this.trayWidth}px`)
      // this.renderer.setStyle(appPageTrayHolder, 'zIndex', this.zIndex);

      // this.renderer.setStyle(appPageInnerTrayHolder, 'width', `${this.trayWidthInner}px`);
      // this.renderer.setStyle(appPageInnerTrayHolder, 'right', `-${this.trayWidthInner}px`);

      // We're gonna cheat and use the fromEvent observable for both resize & scroll to position appropriately & dynamically
      this.resizeObservable$ = fromEvent(window, 'resize');
      this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
        this.onWindowScroll(evt);
      });

      this.scrollObservable$ = fromEvent(window, 'scroll');
      this.scrollSubscription$ = this.scrollObservable$.subscribe(evt => {
        this.onWindowScroll(evt);
      });

    }, 100);

  }

  ngOnChanges(changes: SimpleChanges) {
    // We use this to check to see if our contolling component did something elsewhere, so we can control
    // the tray independently. Meanwhile, don't ever use this.var = !this.var in a function attached to SimpleChanges.
    // Oi, gevalt.
    if (changes.visible && changes.visible.currentValue !== changes.visible.previousValue) {
      this.toggle(changes.visible.currentValue);
    }
    if (changes.innerVisible && changes.innerVisible.currentValue !== changes.innerVisible.previousValue) {
      this.toggleInnerTray(changes.innerVisible.currentValue);
    }
  }

  // Invoked from the change -- either set the right to is' own declared width or to zero
  toggle(val: any) {
    this.visible = val;
    const rightPos = (this.visible) ? `0px` : `-${this.trayWidth}px`;
    const appPageTrayHolder = document.getElementById('appPageTray-holder') as HTMLElement;
    // this.renderer.setStyle(appPageTrayHolder, 'right', rightPos)
    this.innerToggle.emit(this.visible);
  }

  // Then, from the tray itself, invoke the toggle function. We can also set if we want to close the secondary tray
  // discretely using closeBoth -- default set to true.

  // TODO: We have some more combination functions to write here, but this is a solid start. I think.
  toggleClick() {
    this.visible = !this.visible;
    this.toggle(this.visible);

    if (this.closeBoth && this.innerVisible) {
      this.toggleClickInnerTray();
    }
  }

  // Grab just the inner, but second verse same as the first.
  toggleInnerTray(val: any) {
    const appPageTrayHolder = document.getElementById('appPageTray-holder') as HTMLElement;
    const dammit = appPageTrayHolder.offsetWidth;

    this.innerVisible = val;
    const rightPos = (this.innerVisible) ? `${dammit}px` : `-${this.trayWidthInner}px`;
    const appPageInnerTrayHolder = document.getElementById('appPageInnerTray-holder') as HTMLElement;

    // this.renderer.setStyle(appPageInnerTrayHolder, 'right', rightPos);
    // this.renderer.setStyle(appPageInnerTrayHolder, 'width', `${this.trayWidthInner}px`);

    this.innerTrayToggle.emit(this.innerVisible);
  }

  toggleClickInnerTray() {
    this.innerVisible = !this.innerVisible;
    this.toggleInnerTray(this.innerVisible);
  }

  // The standard double-sided DOM case determinant, because it's 2021 and this is still a thing.
  onWindowScroll($ev: any) {

    this.navbar = document.getElementById('navbarTop') as HTMLElement;
    if (this.navbar) {

      const bar = this.navbar.getBoundingClientRect();
      // const setHeight = window.innerHeight - this.navbar.offsetHeight + 'px';

      let setHeight = window.innerHeight + 'px';;

      if (this.navbar.classList.value.includes('fixed')) {
        setHeight = window.innerHeight - this.navbar.offsetHeight + 'px';
        this.navbarTop = this.navbar.offsetHeight;
      } else {
        setHeight = window.innerHeight + 'px';
        this.navbarTop = 0;
      }

      let matches: any = [];
      matches = document.querySelectorAll('.traySetHeight');
      matches.forEach((i: any) => {
        this.renderer.setStyle(i, 'height', setHeight);
      });

      const appPageTrayHolder = document.getElementById('appPageTray-holder') as HTMLElement;
      // this.renderer.setStyle(appPageTrayHolder, 'top', `${this.navbar.offsetHeight}px`);
      // this.renderer.setStyle(appPageTrayHolder, 'zIndex', `${this.zIndex}`);
    }

  }

  ngOnDestroy(): void {
    this.scrollSubscription$ && this.scrollSubscription$.unsubscribe();
    this.resizeSubscription$ && this.resizeSubscription$.unsubscribe();
  }

}
