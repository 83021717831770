import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import gql from 'graphql-tag';
import { Company } from '../company.model';


export const COMPANY_LIST_PICKER_QUERY_NAME = 'CompanyListPickerQuery';

export interface CompanyListPickerQueryResults {
  companies: [Company];
  total: {
    aggregate: {
      count: number;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ListCompaniesPickerGqlService extends Query<CompanyListPickerQueryResults> {

  document = gql`
    query ${COMPANY_LIST_PICKER_QUERY_NAME} (
      $limit: Int!, 
      $offset: Int!,
      $search: String
      ) {
      companies: Company(
        limit: $limit, 
        offset: $offset,
        order_by: { name: asc },
        where: { name: { _ilike: $search } }
      ) {
        id
        name
        companyPermissions (where: {granted: {_eq: true}}) {
          granted
          companyPermission {
            id
            name
          }
        }
        image {
          id
          url
        }
        customers {
          id
        }
      }
      total: Company_aggregate(where: { name: { _ilike: $search } }) {
        aggregate {
          count
        }
      }
    }
  `;
}
